import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";

interface CircleProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

interface AuthStatusStylesProps
  extends React.HTMLAttributes<HTMLParagraphElement> {
  active?: boolean;
}

export const CardWrapperStyles = styled.a`
  max-width: 30rem;
  width: 100%;
  min-height: 5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0 auto;
  ${({ theme }) => `
      border: 1.5px solid ${theme.colors.backgroundPrimary};
      background-color: ${theme.colors.backgroundPrimary};
  `}
  :hover {
    ${({ theme }) => `
      border: 1.5px solid ${theme.colors.buttonPrimaryBgc};
    `}
  }
`;

export const ArrowRightStyles = styled(BackIcon)`
  transform: rotate(180deg);
  height: 16px;
  width: 16px;
  vertical-align: middle;
  ${({ theme }) => `fill: ${theme.colors.buttonPrimaryBgc};`}
`;

export const CardPositionerStyles = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  svg {
    width: 36px;
    height: 36px;
    padding: 7px;
    background: #f0f0f0;
    border-radius: 50%;
  }
  path {
    ${({ theme }) => `fill: ${theme.colors.buttonPrimaryBgc};`}
  }
  span {
    ${({ theme }) => `color: ${theme.colors.buttonPrimaryBgc};`}
  }
`;

export const CardImgStyles = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 0.8rem;
`;

export const WarningStyles = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.8rem;
`;

export const CardTextStyles = styled.div`
  margin-left: 10px;
  font-size: 1.4rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const CardTitleStyles = styled.div`
  font-size: 1.6rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.buttonPrimaryBgc};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 30rem;
  margin: 0 auto;
`;

export const CardArrowStyles = styled.img``;

export const CircleStyles = styled.div<CircleProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.textSuccess : theme.colors.error};
`;

export const AuthStatusStyles = styled.p<AuthStatusStylesProps>`
  padding: 0.4rem 1rem 0.6rem;
  border-radius: 8px;
  font-size: 1.4rem;
  text-align: left;
  color: ${({ theme, active }) =>
    active ? theme.colors.textSuccess : theme.colors.error};
  // ${({ theme }) => theme.colors.white};
  background-color: unset;
  font-weight: bold;
  margin-bottom: -3px;
`;

export const AuthTextStyles = styled.p`
  font-size: 1.4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.textSuccess};
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 0.4rem;
`;

export const GridForCardStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem 4.5rem;

  @media (max-width: 880px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
