import React, { ReactNode } from "react";
import {
  ButtonsWrapperStyles,
  ModalButtonStyles,
  ModalStyles,
  ModalTextStyles,
  ModalWrapperStyles,
} from "./styles";

interface AlertProps {
  yesno?: boolean;
  ok?: boolean;
  children: ReactNode;
  onClickYes?: () => void;
  onClickNo?: () => void;
  onClickOk?: () => void;
}

const Alert: React.FC<AlertProps> = ({
  yesno,
  ok,
  children,
  onClickYes,
  onClickNo,
  onClickOk,
}) => {
  return (
    <ModalWrapperStyles>
      <ModalStyles>
        <ModalTextStyles>{children}</ModalTextStyles>
        {yesno && (
          <ButtonsWrapperStyles>
            <ModalButtonStyles onClick={onClickYes}>Tak</ModalButtonStyles>
            <ModalButtonStyles
              style={{ background: "#21884A" }}
              onClick={onClickNo}
            >
              Nie
            </ModalButtonStyles>
          </ButtonsWrapperStyles>
        )}
        {ok && <ModalButtonStyles onClick={onClickOk}>OK</ModalButtonStyles>}
      </ModalStyles>
    </ModalWrapperStyles>
  );
};

export default Alert;
