import styled from 'styled-components';
import { space } from 'styled-system';
import { ReactComponent as LogoColoredIcon } from './CyberID_logo.svg';

export const StyledLogoColored = styled(LogoColoredIcon)`
  ${space};
  ${({ width, height }) => `
    width: ${width ? width : '12rem'};
    height: ${height ? height : '12rem'};
  `}
`;
