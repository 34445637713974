import { FC, ReactNode } from "react";
import { FlexStyles } from "../../styles/FlexStyles";
import { CheckTextStyles, SuccessMessageWrapperStyles } from "./styles";

interface StatusInputWrapperProps {
  children: ReactNode;
  success?: string;
}

const StatusInputWrapper: FC<StatusInputWrapperProps> = ({
  children,
  success,
}) => {
  return (
    <div>
      <FlexStyles pl={"10%"} pr={"10%"}>
        {children}
      </FlexStyles>
      <SuccessMessageWrapperStyles mt={-3} mb={3}>
        {success && <CheckTextStyles block>{success}</CheckTextStyles>}
      </SuccessMessageWrapperStyles>
    </div>
  );
};

export default StatusInputWrapper;
