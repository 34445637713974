import React from "react";
import {
  CardTextStyles,
  CardTitleStyles,
  CardWrapperStyles,
  CardPositionerStyles,
  WarningStyles,
  AuthTextStyles,
} from "./styles";
import { WrapperStyles } from "../../styles/WrapperStyles";
import { parseDate } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IdentificationCardProps {
  text: string;
  Icon: React.ElementType;
  title?: string;
  account?: boolean;
  href?: string;
  onClick?: () => void;
  confirmed_at?: any;
  warning?: boolean;
}

const IdentificationCard: React.FC<IdentificationCardProps> = ({
  text,
  Icon,
  title,
  account = false,
  href,
  onClick,
  confirmed_at,
  warning,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const active = !!confirmed_at;

  const handleClick = () => {
    if (href) {
      navigate(href);
    }
  };

  if (account) {
    return (
      <CardWrapperStyles
        style={{ cursor: "pointer", marginBottom: "15px", marginTop: "15px" }}
        onClick={handleClick}
      >
        <CardPositionerStyles>
          <Icon />
          {/*<CardImg src={img} />*/}
          <CardTextStyles>{t(text)}</CardTextStyles>
        </CardPositionerStyles>
      </CardWrapperStyles>
    );
  }

  return (
    <WrapperStyles style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <CardTitleStyles>
        {/*{!warning && <Circle active={active} />}*/}
        {active && warning && <WarningStyles src="/icons/warningSmall.png" />}
      </CardTitleStyles>
      <CardWrapperStyles
        onClick={active ? handleClick : undefined}
        style={{
          cursor: active ? "pointer" : "not-allowed",
        }}
      >
        {/* <CardWrapperStyles
        href={href}
        onClick={onClick}
        style={{
          cursor: active ? "pointer" : "not-allowed",
        }}
      > */}
        <CardPositionerStyles>
          <Icon />
          {/*<CardImg src={img} />*/}
          <CardTextStyles>
            <span>{title}</span>
            <br />
            {t(text)}
          </CardTextStyles>
        </CardPositionerStyles>
      </CardWrapperStyles>
      {active && (
        <AuthTextStyles>
          {parseDate(
            new Date(confirmed_at === true ? "true" : confirmed_at),
            t
          )}
          <br />
          {text === "bankAuthorization" && t("bankName")}
        </AuthTextStyles>
      )}
    </WrapperStyles>
  );
};

export default IdentificationCard;
