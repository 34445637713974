import { useEffect, useState } from "react";
import qs from "qs";

declare const location: Location;

export const parseURL = (): qs.ParsedQs =>
  qs.parse(location.search, { ignoreQueryPrefix: true });

// todo do i need this?
export const useIsBelowBreakpointHook = (breakpoint?: number): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const updateSize = () => {
      if (breakpoint) {
        setIsMobile(window.innerWidth <= breakpoint);
      } else {
        setIsMobile(window.innerWidth <= 768);
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [breakpoint]);

  return isMobile;
};

export const validateEmail = (email: string): boolean => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateNickname = (nickname: string): boolean => {
  let re = /[ąćęłńóśźżłĄĆĘŁŃÓŚŹŻŁ]/;
  return !re.test(nickname);
};

export const validatePhone = (phone: string): boolean => {
  let re = /\d{9}/;
  return re.test(phone);
};

export const validatePassword = (password: string): boolean => {
  let re = new RegExp(
    "(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );
  return re.test(password);
};

export const validatePostCode = (code: string): boolean => {
  let re = /^[0-9]{2}-[0-9]{3}/;
  return re.test(code) && code.length === 6;
};

export const replacePostCode = (code: string): string => {
  if (!code) return "";
  if (/[a-zA-Z]/g.test(code)) return code.replace(/\D/g, "");
  if ((code.match(/\-/g) || []).length === 2) return "";
  if (code && code.length > 6) return code.slice(0, 6);
  if (code.length === 3 && code[2] !== "-")
    return code.slice(0, 2) + "-" + code.slice(2);
  if (code.length > 3 && code.indexOf("-") !== 2)
    return (
      code.replace("-", "").slice(0, 2) + "-" + code.replace("-", "").slice(2)
    );
  return code.replace("-", "").replace(/^(\d{2})(\d{1})/, "$1-$2");
};

export const validatePesel = (pesel: string): boolean => {
  if (typeof pesel !== "string") return false;

  let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  let controlNumber = parseInt(pesel.substring(10, 11));

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
  }
  sum = sum % 10;
  return (10 - sum) % 10 === controlNumber;
};

export const validateAccountNumber = (accountNumber: string): boolean => {
  if (typeof accountNumber !== "string") return false;

  if (accountNumber.length !== 26) return false;

  return true;
};

export const cookieHelper = {
  setCookie: (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie: function getCookie(cname: string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
};

export const formatDate = (d: Date, char?: string): string =>
  d
    ? `${d.getDate() <= 9 ? "0" + d.getDate() : d.getDate()}${char || "-"}${
        d.getMonth() + 1 <= 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
      }${char || "-"}${d.getFullYear()}`
    : "";

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("" + Math.floor(o / 60)).slice(-2); // + ":" + ("00" + (o % 60)).slice(-2);
  // return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
};

export const formatTime = (d: Date, char?: string): string =>
  d
    ? (d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()) +
      `${char || ":"}` +
      (d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()) +
      `${char || ":"}` +
      (d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds())
    : "";

export const debounce = (f: Function, ms: number): Function => {
  let isCooldown = false;
  return function (this: any) {
    if (isCooldown) return;
    f.apply(this, arguments);
    isCooldown = true;
    setTimeout(() => (isCooldown = false), ms);
  };
};

export const parseDate = (date = new Date(), t: any): string => {
  return t("cardDateConfirmed", {
    date: formatDate(date, "."),
    hour: formatTime(date),
    timezone: getTimeZone(),
  });
};
