import { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { PageCenterWrapperStyles } from "../../styles/WrapperStyles";
import { PageErrorStyles } from "../../styles/TypographyStyles";
import { embedKontomatik } from "../../helpers/kontomatik";
import { LogoStyles } from "../../components/Header/styles";
import Level5Tutorial from "./Level5Tutorial";
import { http } from "../../helpers/http";

const Level5 = () => {
  const isMobile = window.mobileAndTabletCheck();
  const userToken = localStorage.getItem("user-token");
  const userLevel5Tutorial = localStorage.getItem("user-level5-tutorial");
  const [error, setError] = useState("");

  useEffect(() => {
    embedKontomatik({
      client: "carbonet-test",
      divId: "kontomatik",
      onSuccess: function (target, sessionId, sessionIdSignature, options) {
        const postData = {
          verification: {
            name: "kontomatik",
            sessionId: sessionId,
            sessionIdSignature: sessionIdSignature,
          },
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${userToken}`,
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(postData),
        };
        http("verifications", requestOptions, true)
          .then((response) => {
            if (response.status === 202) {
              console.log("autoryzacja w toku");
            }
          })
          .catch((error) => {
            console.error("error:", error);
          });
      },
    });
  }, [userToken]);

  return (
    <Layout menuButton>
      {error ? (
        <PageErrorStyles>{error}</PageErrorStyles>
      ) : (
        <PageCenterWrapperStyles
          maxWidth={600}
          style={{
            paddingBottom: 40,
            marginTop: isMobile ? "100px" : 0,
          }}
        >
          <div id="kontomatikLogo" style={{ width: "270px"}}>
            <LogoStyles $height={7} src={"/logo.svg"} />
          </div>
          <div id="kontomatik" style={{ marginTop: "80px" }} />
        </PageCenterWrapperStyles>
      )}
      {!userLevel5Tutorial && <Level5Tutorial />}
    </Layout>
  );
};

export default Level5;
