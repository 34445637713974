import styled from "styled-components";
import { LinkStyles } from "../../styles/TypographyStyles";

interface StyledNavLinkProps {
  readonly match: string;
  navTo: string;
}

export const StyledNavLinkStyles = styled(LinkStyles)<StyledNavLinkProps>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 15px 20px 10px;
  width: 90%;
  border-radius: 0 50px 50px 0;
  font-size: 1.5rem;
  background-color: ${({ theme, match }) =>
    match === "true"
      ? theme.colors.buttonPrimaryBgc
      : theme.colors.backgroundPrimary};
  color: ${({ theme, match }) =>
    match === "true" ? theme.colors.white : theme.colors.textPrimary};

  path {
    fill: ${({ match }) => (match === "true" ? "white !important" : "inherit")};
  }
`;
