import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pl1 from './pl/common.json';
import pl2 from './pl/login.json';
import pl3 from './pl/example.json';
import pl4 from './pl/register.json';
import pl5 from './pl/auth.json';
import pl6 from './pl/identification.json';
import pl7 from './pl/account.json';
import pl8 from './pl/sidebar.json';
import pl9 from './pl/errors.json';

import en1 from './en/common.json';
import en2 from './en/login.json';
import en3 from './en/example.json';
import en4 from './en/register.json';
import en5 from './en/auth.json';
import en6 from './en/identification.json';
import en7 from './en/account.json';
import en8 from './en/sidebar.json';
import en9 from './en/errors.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    resources: {
      en: {
        translation: { ...en1, ...en2, ...en3, ...en4, ...en5, ...en6, ...en7, ...en8, ...en9 },
      },
      pl: {
        translation: { ...pl1, ...pl2, ...pl3, ...pl4, ...pl5, ...pl6, ...pl7, ...pl8, ...pl9 },
      },
    },
  });

i18n.init({
  interpolation: {
    format: function (value, format, lng) {
      // if (value instanceof Date) return moment(value).format(format);
      if (value instanceof Date) return value.toString();
      if (typeof value === 'number') return new Intl.NumberFormat().format(value);
      return value;
    },
  },
});

export { i18n };
