import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import Layout from "../../components/Layout/Layout";
import { WrapperStyles } from "../../styles/WrapperStyles";
import { PageTitleStyles } from "../../styles/TypographyStyles";
import { ButtonStyles, MnemonicButtonStyles } from "../../styles/ButtonStyles";
import { useTranslation } from "react-i18next";
import { BoxStyles } from "../../styles/BoxStyles";
import { http } from "../../helpers/http";
import { FlexStyles } from "../../styles/FlexStyles";
import Popup from "../../components/Popup/Popup";
import IVotingModule from "../../sdk/iVoting_sdk.js";

const Seed: React.FC = () => {
  const [isSeed, setIsSeed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [entr, setEntr] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const generateMnemonicWords = async () => {
    const iVotingSDK = await IVotingModule();
    const seed = await new iVotingSDK.c_seed();

    const entropy_bytes = seed.get_entropy_bytes();
    setEntr(entropy_bytes);

    const key_manager = new iVotingSDK.c_key_manager_BIP32(entropy_bytes);
    const root_keypair = key_manager.get_root_key();

    localStorage.setItem(
      "privateKey",
      JSON.stringify(root_keypair.m_secret_key)
    );

    const seed_words = seed.get_words_of_seed();
    setMnemonicWords(seed_words);

    setPublicKey(JSON.stringify(root_keypair.m_public_key));
  };

  const bc_addres = process.env.REACT_APP_NODE_URL;
  const bc_user = process.env.REACT_APP_NODE_USER;

  //mnemonic variables
  const [mnemonicWords, setMnemonicWords] = useState([]);
  const [randomizeMnemo, setRandomizeMemo] = useState<string[]>([]);
  const [checkRandomizeMnemo, setCheckRandomizeMemo] = useState<string[]>([]);
  const [publicKey, setPublicKey] = useState("");
  const [mnemonicScreen, setmnemonicScreen] = useState(0);
  const [randomizeMnemoStatus, setRandomizeMnemoStatus] = useState(false);

  const fetchKey = () => {
    http(
      `public-key`,
      {
        method: "GET",
      },
      true
    )
      .then((response) => {
        if (response.public_key != null) {
          navigate("/identification");
        } else {
          generateMnemonicWords();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(t("serverError"));
      });
  };

  useEffect(() => {
    fetchKey();
  }, []);

  const handleApproveMnemonic = async () => {
    if (!bc_addres || !bc_user) {
      console.error("bc_addres or bc_user is undefined");
      return;
    }

    const inputString = publicKey;
    const byteArray = inputString.slice(1, -1).split(",").map(Number);
    const hexString = byteArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
      http(
        `public-key`,
        {
          method: "POST",
          body: JSON.stringify({
            public_key: JSON.parse(publicKey).concat(entr),
          }),
        },
        true
      )
        .then((response) => {
          navigate("/identification");
        })
        .catch((error) => {
          console.error("error key:", error);
        });
    /**fetch(bc_addres, {
      method: "POST",
      headers: {
        "content-type": "application/json;",
        Authorization: "Basic " + btoa(bc_user),
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "authorize_voter_by_issuer",
        params: {
          pk: hexString,
          auth_level: 0,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result.status === "done") {
          should be here
        }
      })
      .catch((error) => {
        console.error("rpc authorize_voter_by_issuer error: ", error);
      });*/
  };

  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((element, index) => element === arr2[index]);
  };

  const handleMemonicCheck = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (
      checkRandomizeMnemo.reduce(
        (acc, val) =>
          val === e.currentTarget.innerHTML.replace(/\s+/g, "") ? acc + 1 : acc,
        0
      ) !==
      randomizeMnemo.reduce(
        (acc, val) =>
          val === e.currentTarget.innerHTML.replace(/\s+/g, "") ? acc + 1 : acc,
        0
      )
    ) {
      e.currentTarget.style.backgroundColor = "#CCCCCC";
      setCheckRandomizeMemo([
        ...checkRandomizeMnemo,
        e.currentTarget.innerHTML.replace(/\s+/g, "") as never,
      ]);
    }
  };

  const handleMemonicCheckDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    const targetWord = e.currentTarget.innerHTML.replace(/[0-9\s.]/g, "");
    const targetIndex = randomizeMnemo.indexOf(targetWord);

    if (targetIndex !== -1) {
      const updatedCheckRandomizeMnemo = checkRandomizeMnemo.filter(
        (item) => item !== targetWord
      );
      setCheckRandomizeMemo(updatedCheckRandomizeMnemo);

      const randomizeMnemoContainer = document.querySelector(".randomizeMnemo");
      if (randomizeMnemoContainer) {
        const spans = randomizeMnemoContainer.querySelectorAll("span");
        if (spans[targetIndex]) {
          spans[targetIndex].style.backgroundColor = "white";
        }
      }

      setRandomizeMnemoStatus(false);
    }
  };

  //modal next button
  const handleSkipClick = () => {
    //setShowModal(true);
    handleApproveMnemonic();
    setShowModal(false);
    navigate('/identification');
  };

  const handleModalConfirm = () => {
    handleApproveMnemonic();
    setShowModal(false);
    navigate('/identification');
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const modalContent = (
    <Popup
      title={t("modalTitleSeedKey")}
      description={t("modalDescriptionSeedKey")}
      form={
        <FlexStyles justifyContent="center">
          <ButtonStyles onClick={handleModalConfirm}>
            {t("yesSkip")}
          </ButtonStyles>
          <BoxStyles m="0 0.5em" />
          <ButtonStyles onClick={handleModalCancel}>{t("cancel")}</ButtonStyles>
        </FlexStyles>
      }
    />
  );

  if (!loading) {
    if (mnemonicScreen === 0) {
      return (
        <>
          <Layout
            hideLogo={true}
            bgc="#F0F0F0"
            //menuButton='t'
            menuTitle={"Poziom 0"}
          >
            <WrapperStyles
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                maxWidth: "30rem",
                margin: "0 auto",
                fontSize: "14px",
              }}
            >
              {showModal && modalContent}
              {!showModal && (
                <>
                  <PageTitleStyles
                    mb={3}
                    mt={0}
                    style={{ alignSelf: "flex-start", fontSize: "22px" }}
                  >
                    {t("seedKeyInformation")}
                  </PageTitleStyles>
                  <BoxStyles color={"#595959"} fontSize={"14px"}>
                    {t("seedPhraseInformation")}
                  </BoxStyles>
                  <BoxStyles width="100%" pb={10} mt={3}>
                    {mnemonicWords.map((word, index) => (
                      <MnemonicButtonStyles key={index}>
                        {index + 1 + ". " + word}{" "}
                      </MnemonicButtonStyles>
                    ))}
                  </BoxStyles>
                  <FlexStyles
                    justifyContent={"center"}
                    display="flex"
                    mt={"1em"}
                    mb={"2em"}
                    style={{ textAlign: "center", color: "#BB173D" }}
                  >
                    {t("seedPhraseInformation2")}
                  </FlexStyles>
                  <FlexStyles
                    justifyContent={"center"}
                    display="flex"
                    mt={"1em"}
                    mb={"2em"}
                  >
                    <FlexStyles
                      justifyContent="space-between"
                      width="100%"
                      mt="1em"
                      mb="2em"
                    >
                      <BoxStyles m="0 1em">
                        <ButtonStyles
                          onClick={() => {
                            setRandomizeMemo(
                              [...mnemonicWords].sort(() => Math.random() - 0.5)
                            );
                            setmnemonicScreen(1);
                            handleApproveMnemonic();
                          }}
                        >
                          {t("next")}
                        </ButtonStyles>
                      </BoxStyles>
                      <BoxStyles m="0 1em">
                        <ButtonStyles onClick={handleSkipClick}>
                          {t("skip")}
                        </ButtonStyles>
                      </BoxStyles>
                    </FlexStyles>
                  </FlexStyles>
                </>
              )}
            </WrapperStyles>
          </Layout>
        </>
      );
    }
    //tymczasowo wylaczane! nie kasowac kodu!
    if (mnemonicScreen === 1) {
      return (
        <>
          <Layout
            hideLogo={true}
            bgc="#F0F0F0"
            //menuButton='t'
            menuTitle={"Poziom 0"}
          >
            <WrapperStyles
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                maxWidth: "30rem",
                margin: "0 auto",
                fontSize: "14px",
              }}
            >
              <BoxStyles width="100%" pb={15} mt={3} minHeight="275px">
                <center>{t("validMnemonic")}</center>
                <div>
                  {checkRandomizeMnemo.map((word, index) => (
                    <MnemonicButtonStyles
                      key={index}
                      onClick={handleMemonicCheckDelete}
                    >
                      {index + 1 + ". " + word}{" "}
                    </MnemonicButtonStyles>
                  ))}
                </div>
              </BoxStyles>
              <BoxStyles
                width="100%"
                pb={20}
                mt={3}
                mb={3}
                className="randomizeMnemo"
              >
                <hr />
                <br />
                {randomizeMnemo.map((word, index) => (
                  <MnemonicButtonStyles
                    key={index}
                    onClick={handleMemonicCheck}
                  >
                    {word}{" "}
                  </MnemonicButtonStyles>
                ))}
              </BoxStyles>

              {areArraysEqual(mnemonicWords, checkRandomizeMnemo) === true ? (
                <ButtonStyles
                  onClick={() => {
                    handleApproveMnemonic();
                  }}
                >
                  {t("next")}
                </ButtonStyles>
              ) : (
                <ButtonStyles style={{ background: "#CCCCCC" }}>
                  {t("next")}
                </ButtonStyles>
              )}
            </WrapperStyles>
          </Layout>
        </>
      );
    }
  } else {
    return (
      <>
        <Layout>
          <WrapperStyles
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              maxWidth: "30rem",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Circles color="#818181" height={50} width={50} />
            </div>
          </WrapperStyles>
        </Layout>
      </>
    );
  }
  return null;
};
export default Seed;
