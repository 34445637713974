import styled from "styled-components";
import {
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  space,
  layout,
  flexbox,
} from "styled-system";

interface FlexStylesProps extends SpaceProps, LayoutProps, FlexboxProps {}

interface FlexTwoItemsContainerProps {
  breakSmall: string;
  width: string;
}

interface FlexThreeItemsContainerProps {
  breakMiddle: string;
  breakSmall: string;
}

export const FlexStyles = styled.div<FlexStylesProps>`
  ${space}
  ${layout}
  ${flexbox}
  display: flex;
`;

export const FlexTwoItemsContainerStyles = styled.div<FlexTwoItemsContainerProps>`
  display: flex;
  justify-content: space-between;
  > div {
    width: 50%;
  }

  ${({ breakSmall }) => `
    @media (max-width: ${breakSmall}) {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  `}
`;

export const FlexThreeItemsContainerStyles = styled(
  FlexStyles
)<FlexThreeItemsContainerProps>`
  justify-content: space-between;
  > div {
    width: 30%;
  }

  ${({ breakMiddle, breakSmall }) => `
    @media (max-width: ${breakMiddle}) {
      flex-wrap: wrap;
      > div {
        flex: 0 0 45%;
      }
      > div:last-child {
        flex: 1 0 100%;
      }
    }
    @media (max-width: ${breakSmall}) {
      flex-direction: column;
      > div {
        width: 100%;
      }
    }
  `}
`;
