const params = new URLSearchParams(window.location.search);

const samlRequest = params.get("SAMLRequest");
const relayState = params.get("RelayState");

export type RequestOptions = {
  method?: string;
  body?:
    | string
    | URLSearchParams
    | FormData
    | Blob
    | ArrayBuffer
    | DataView
    | null
    | any
    | undefined;
  headers?: Record<string, string>;
};

export const http = async (
  url: string,
  options: RequestOptions,
  auth: boolean
): Promise<any> => {
  const body = options.body;

  if (body && typeof body !== "string" && typeof body !== "object")
    options.body = JSON.stringify(options.body);

  if (auth) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${localStorage.getItem("user-token")}`,
    };
  }

  const headers =
    "object" !== typeof body
      ? {
          ...options?.headers,
          "Content-Type": "application/json",
          Accept: "application/json",
          method: options.method || "GET",
        }
      : {
          ...options?.headers,
          Accept: "application/json",
          method: options.method || "GET",
        };

  const currentDomain = window.location.protocol + "//" + window.location.host;
  const response = await fetch(httpURL(url), {
    ...options,
    headers,
  });

  if (!response.ok) {
    console.log(response.status);
    if (response.status === 401) {

      console.log("try log out");
      window.localStorage.removeItem("user-token");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("email");

      if(samlRequest || relayState){
        window.location.href = `/login-kc?SAMLRequest=${samlRequest}&RelayState=${relayState}`;
      }
      else{
        window.location.href = currentDomain;
      }

    }

    if (response.status === 422) throw new Error(await response.text());
    if (response.status === 500) throw new Error('{"status":500}');
    if (response.status === 400 || response.status === 404)
      throw new Error('{"status":400}');
  }

  if (response.statusText === "No Content") {
    return null;
  }

  return response.json();
};

export const httpURL = (url: string) => {
  return process.env.REACT_APP_API_URL + url;
};
