import { ThemeProvider } from "styled-components";
import { DefaultThemeStyles } from "./styles/theme/theme";
import { GlobalStyles } from "./styles/GlobalStyles";
import RouterController from "./router/RouterController";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "../src/errorBoundary/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={DefaultThemeStyles}>
        <GlobalStyles />
        <RouterController />
        <ToastContainer position="bottom-right" />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
