import { FC, ReactNode } from "react";
import { PageTitleStyles } from "../../styles/TypographyStyles";
import { BoxStyles } from "../../styles/BoxStyles";
import { WrapperStyles } from "./styles";

interface PopupProps {
  icon?: ReactNode;
  title: string;
  description: string;
  form?: ReactNode;
}

const Popup: FC<PopupProps> = ({ icon, title, description, form }) => {
  return (
    <>
      <WrapperStyles>
        {icon}
        <PageTitleStyles
          mt={2}
          mb={3}
          style={{ alignSelf: "flex-start", fontSize: "22px" }}
        >
          {title}
        </PageTitleStyles>
        <BoxStyles
          color={"#595959"}
          fontSize={"14px"}
          style={{ fontSize: "14px" }}
          mb={3}
        >
          {description}
        </BoxStyles>
        {form}
      </WrapperStyles>
    </>
  );
};

export default Popup;
