import styled from "styled-components";
import { FlexStyles } from "./FlexStyles";
import {
  LinkStyles,
  PageDescriptionStyles,
  PageTitleStyles,
} from "./TypographyStyles";

export const ContentStyles = styled(FlexStyles)`
  max-width: 260px;
  min-height: 400px;
  flex-direction: column;
  justify-content: space-between;

  ${PageTitleStyles} {
    span {
      color: ${({ theme }) => theme.colors.buttonPrimaryBgc};
      margin-right: 8px;
      font-size: 2.6rem;
    }
  }
  ${PageDescriptionStyles} {
    line-height: 2.2rem;
  }
  ${LinkStyles} {
    display: inline-block;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }
`;
