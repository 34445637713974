import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Circles } from "react-loader-spinner";
import Input from "../../components/Input/Input";
import { WrapperStyles } from "../../styles/WrapperStyles";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { ErrorStyles } from "../../components/Input/styles";
import {
  BoldedTextStyles,
  LinkStyles,
  PageSuccessStyles,
} from "../../styles/TypographyStyles";
import { parseURL } from "../../helpers/helpers";
import PhoneInput from "../../components/Input/PhoneInput";
import { useGlobalStateDispatchContext } from "../../contexts/GlobalStateContext";
import { useTranslation, Trans } from "react-i18next";
import { BoxStyles } from "../../styles/BoxStyles";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { http, httpURL } from "../../helpers/http";
import { OrHr } from "../../components/OrHr/OrHr";
import { FlexStyles } from "../../styles/FlexStyles";
import { validateEmail, validateNickname } from "../../helpers/helpers";

const Login: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      navigate("/account");
    }
  }, []);

  const params = parseURL();
  const { setLang } = useGlobalStateDispatchContext();
  const { setSessionTime } = useGlobalStateDispatchContext();
  const { t } = useTranslation();

  const [form, setForm] = useState<Record<string, any>>({
    nickname: "",
    email: "",
    password: "",
    language: {
      areaCodes: null,
      dialCode: "48",
      iso2: "pl",
      name: "Poland (Polska)",
      priority: 0,
    },
    phone: "+48",
    termsAndConditionsAcceptation: false,
  });

  const [loginResponse, setLoginResponse] = useState("");
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    nickname: "",
    email: "",
    password: "",
    phone: "",
    termsAndConditionsAcceptation: "",
  });
  const [isLoading, setLoading] = useState(false);

  const handleLangChange = (lang: string) => setLang(lang);

  const handleSignInErrors = () => {

    const errors = {
      email: "",
      nickname: "",
      password: "",
      phone: "",
      termsAndConditionsAcceptation: "",
    };
    setLoginError("");
    if (form.email.length && !validateEmail(form.email)) {
      errors.email = "invalidEmail";
      console.log(errors.email)
    }
    if (form.nickname.length && !validateNickname(form.nickname)) {
      errors.nickname = "invalidNickname";
      console.log(errors.nickname)
    }
    if (!form.termsAndConditionsAcceptation) {
      errors.termsAndConditionsAcceptation = "requiredField";
      console.log(errors.termsAndConditionsAcceptation)
    }
    setValidationErrors(errors);
    return !Object.keys(errors).length;
  };

  const handleSignIn = () => {
    if (handleSignInErrors()) return;

    setLoading(true);
    const postData = {
      email: form.email,
      nickname: form.nickname,
      phone: form.phone.replace(/ /g, "").replace("+", ""),
      password: form.password,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    };

    fetch(httpURL("login"), requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            setLoginError("nicknameOrPasswordInvalid");
          /**  setValidationErrors({
              ...validationErrors,
              nickname: "invalidNickname",
              password: "invalidPassword",
            });*/
            setLoading(false);
          }
        } else {
          if (response.status === 200) {
            response.text().then((text) => {
              setShowCaptcha(true);
              setLoginResponse(text);
            });
            setLoginError("");
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error("error:", error);
        setLoading(false);
      });
  };

  const showPasswordResetSuccess = () => {
    setTimeout(() => navigate("/"), 10000);

    return (
      <BoxStyles mt={2}>
        <PageSuccessStyles style={{ marginBottom: "1.8rem" }}>
          {t("passwordWasChanged")}
        </PageSuccessStyles>
      </BoxStyles>
    );
  };

  useEffect(() => {
    if (form.language.iso2) handleLangChange(form.language.iso2);
  }, [form.language.iso2]);

  const onCaptchaChange = (value: any | null) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ recaptcha: { token: value } }),
    };
    http(`recaptcha`, requestOptions, true)
      .then((res) => {
        const body = JSON.parse(loginResponse);
        localStorage.setItem("user-token", body.token);
        localStorage.setItem("user", JSON.stringify(body.data));
        localStorage.setItem("confirmed", body.data.confirmed_at);
        setSessionTime(3600);
        navigate("/seed");
      })
      .catch((err) => toast.error(t(err)));
  };

  useEffect(() => {
    setForm(form => ({
      ...form,  // Copy the existing form state
      phone: "",  // Update only the email property
    }));
    setForm(form => ({
      ...form,  // Copy the existing form state
      nickname: "",  // Update only the email property
    }));
  }, [form.email]);

  useEffect(() => {
    setForm(form => ({
      ...form,  // Copy the existing form state
      phone: "",  // Update only the email property
    }));
    setForm(form => ({
      ...form,  // Copy the existing form state
      email: "",  // Update only the email property
    }));
  }, [form.nickname]);

  useEffect(() => {
    setForm(form => ({
      ...form,  // Copy the existing form state
      email: "",  // Update only the email property
    }));
    setForm(form => ({
      ...form,  // Copy the existing form state
      nickname: "",  // Update only the email property
    }));
  }, [form.phone]);

  // pdf terms-and-conditions
  const handlePdfDownload = (event: React.MouseEvent) => {
    event.preventDefault();
    fetch("/backend-pdf")
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
        window.open(url, "_blank");
      });
  };

  return (
    <Layout>
      <WrapperStyles
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          maxWidth: "30rem",
          margin: "0 auto",
        }}
      >
        <Input
          className="testab"
          setState={setForm}
          value={form.email}
          label={t("email")}
          type={"email"}
          name={"email"}
          error={!!validationErrors.email}
          errorMessage={validationErrors.email && validationErrors.email}
        />
        <FlexStyles width="100%" mb={3}>
          <OrHr />
        </FlexStyles>

        <Input
          setState={setForm}
          value={form.nickname}
          label={t("nickname")}
          type={"nickname"}
          name={"nickname"}
          error={!!validationErrors.nickname}
          errorMessage={validationErrors.nickname && validationErrors.nickname}
        />
        <FlexStyles width="100%" mb={3}>
          <OrHr />
        </FlexStyles>

        <PhoneInput
          setState={setForm}
          label={`${t("phoneNumber")} `}
          value={form.phone}
          language={form.language}
          type={"phone"}
          name={"phone"}
          langName={"language"}
          error={validationErrors.phone ? true : false}
          errorMessage={validationErrors.phone && validationErrors.phone}
        />
        <Input
          setState={setForm}
          value={form.password}
          label={t("password")}
          type={"password"}
          name={"password"}
          error={!!validationErrors.password}
          errorMessage={validationErrors.password && validationErrors.password}
        />
        <Input
          smoothLabel
          id="termsAndConditionsAcceptation"
          setState={setForm}
          label={
            <>
              <Trans
                i18nKey="termsAndConditionsAcceptation"
                t={t}
                components={[
                  <LinkStyles
                    target="_blank"
                    href={"/terms-and-conditions"}
                    onClick={handlePdfDownload}
                  />,
                  <LinkStyles
                    target="_blank"
                    href={"/privacy-policy"}
                    onClick={handlePdfDownload}
                  />,
                ]}
              />
            </>
          }
          value={form.termsAndConditionsAcceptation}
          type={"checkbox"}
          name={"termsAndConditionsAcceptation"}
          error={!!validationErrors.termsAndConditionsAcceptation}
          errorMessage={
            validationErrors.termsAndConditionsAcceptation &&
            validationErrors.termsAndConditionsAcceptation
          }
        />
        {isLoading ? (
          <Circles color="#818181" height={50} width={50} />
        ) : (
          <>
            {loginError && (
              <BoxStyles mt={2}>
                <ErrorStyles style={{ marginBottom: "1.8rem" }}>
                  {t(loginError)}
                </ErrorStyles>
              </BoxStyles>
            )}
            {params.reset_password_complete && showPasswordResetSuccess()}
            {showCaptcha && (
              <BoxStyles mb={2}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                  onChange={onCaptchaChange}
                  theme="light"
                />
              </BoxStyles>
            )}

            <BoxStyles mt={2} mb={2}>
              <ButtonStyles onClick={handleSignIn}>{t("login")}</ButtonStyles>
            </BoxStyles>
            <LinkStyles
              onClick={() => window.open("/password-reset", "_blank")}
            >
              {t("areForgetPassword")}
            </LinkStyles>
            <FlexStyles alignItems="center">
              <BoxStyles mr={2}>
                <BoldedTextStyles>{t("areNewUser")}</BoldedTextStyles>
              </BoxStyles>
              <LinkStyles onClick={() => navigate("/register")}>
                {t("singUp")}
              </LinkStyles>
            </FlexStyles>
          </>
        )}
      </WrapperStyles>
      <FlexStyles ml={15} mb={0}>
        V: {"2.5.7"}
      </FlexStyles>
    </Layout>
  );
};

export default Login;
