import { CenterWrapperStyles } from "./styles";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu_button.svg";
import { StyledLogoColored } from "../../assets/icons/StyledLogoColored";
import Sidebar from "../Sidebar/Sidebar";
import LanguageSwitch from "../../translations/LanguageSwitch";
import {
  useGlobalStateContext,
  useGlobalStateDispatchContext,
} from "../../contexts/GlobalStateContext";

interface HeaderProps {
  hideLogo?: boolean;
  menuButton?: boolean;
  menuTitle?: string;
  logoutButton?: boolean;
  withBackButton?: boolean;
}

const Header: React.FC<HeaderProps> = ({ hideLogo, menuButton, menuTitle }) => {
  const { isHamburgerMenu } = useGlobalStateContext();
  const { setIsHamburgerMenu } = useGlobalStateDispatchContext();

  return (
    <>
      {isHamburgerMenu && <Sidebar setIsMenuOpened={setIsHamburgerMenu} />}

      <CenterWrapperStyles
        style={{ borderBottom: menuTitle && "1px solid #cccccc" }}
      >
        {!hideLogo && (
          <a href="/">
            <StyledLogoColored width="14rem" height="14rem" />
          </a>
        )}
        {menuButton && (
          <button data-left onClick={() => setIsHamburgerMenu(true)}>
            <MenuIcon />
          </button>
        )}
        {menuTitle && <span>{menuTitle}</span>}
      </CenterWrapperStyles>
      <LanguageSwitch />
    </>
  );
};

export default Header;
