import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import Layout from "../../components/Layout/Layout";
import Input from "../../components/Input/Input";
import { WrapperStyles } from "../../styles/WrapperStyles";
import {
  PageTitleStyles,
  BoldedTextStyles,
  LinkStyles,
} from "../../styles/TypographyStyles";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { validateEmail } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import { BoxStyles } from "../../styles/BoxStyles";
import { ErrorStyles } from "../../components/ErrorMessage/styles";
import { http } from "../../helpers/http";
import { toast } from "react-toastify";
import { FlexStyles } from "../../styles/FlexStyles";
import { serialize } from "object-to-formdata";
import { useGlobalStateDispatchContext } from "../../contexts/GlobalStateContext";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      navigate("/account");
    }
  }, []);



  const [form, setForm] = useState<Record<string, any>>({
    language: {
      areaCodes: null,
      dialCode: "48",
      iso2: "pl",
      name: "Poland (Polska)",
      priority: 0,
    },
    phone: "+48",
    email: "",
    nickname: "",
    password: "",
    rePassword: "",
    isPhone: false,
    avatar: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    avatar: "",
    phone: "",
    email: "",
    nickname: "",
    password: "",
    rePassword: "",
  });
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { setSessionTime } = useGlobalStateDispatchContext();

  const handleSignUpErrors = () => {
    const errors = {
      avatar: "",
      phone: "",
      email: "",
      nickname: "",
      password: "",
      rePassword: "",
    };

    if (form.avatar.size > 2097152) {
      errors.avatar = "imageSize";
    }

    if (
      form.phone.length > 3 &&
      form.language?.iso2 &&
      isValidPhoneNumber(form.phone, form.language.iso2 as CountryCode)
    ) {
      errors.phone = "invalidPhoneNumber";
    }
    if (form.email.length && !validateEmail(form.email)) {
      errors.email = "invalidEmail";
    }
    if (!form.nickname.length) {
      errors.nickname = "requiredField";
    }
    if (form.nickname.match(/[^a-zA-Z0-9 ]+/)) {
      errors.nickname = t("nicknameCorrect");
    }
    if (!form.password.length) {
      errors.password = "requiredField";
    } else if (
      form.password.length &&
      form.rePassword.length &&
      form.password !== form.rePassword
    ) {
      errors.password = "differentPasswords";
      errors.rePassword = "differentPasswords";
    }
    if (!form.rePassword.length) {
      errors.rePassword = "differentPasswords";
    }
    if (form.password.length < 8) {
      errors.password = "passwordLength";
    }
    if (form.password.length > 128) {
      errors.password = "passwordLengthLong";
    }

    if (
      form.password.length > 7 &&
      form.password.length < 129 &&
      form.rePassword.length > 7 &&
      form.rePassword.length < 129
    ) {
      let passwordcheck = 0;
      if (form.password.match(/[a-z]/g)) {
      } else {
        passwordcheck += 1;
      }

      if (form.password.match(/[A-Z]/g)) {
      } else {
        passwordcheck += 1;
      }

      if (form.password.match(/[0-9]/g)) {
      } else {
        passwordcheck += 1;
      }

      if (form.password.match(/[^a-zA-Z0-9 ]+/)) {
      } else {
        passwordcheck += 1;
      }
      if (passwordcheck > 2) {
        errors.password = t("criteria");
      }
    }

    setValidationErrors(errors);
    return !Object.keys(errors).length;
  };

  useEffect(() => {
    setForm({
     ...form,
     nickname: form.nickname.toLowerCase(),
   });
  }, [form.nickname]);

  const handleSignUp = () => {
    if (handleSignUpErrors()) return;

    const postData = {
      user: {
        email: form.email,
        nickname: form.nickname,
        password: form.password,
        password_confirmation: form.rePassword,
        ...(form.phone.length > 4
          ? { phone: form.phone.replace(/\s+/g, "").replace(`+`, "") }
          : {}),
      },
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    };

    http("signup", requestOptions, true)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify({ ...response.data }));
        localStorage.setItem("phoneCode", form.language.dialCode);
        localStorage.setItem("user-token", response.token);
        localStorage.setItem("confirmed", "null");
        setValidationErrors({
          phone: "",
          email: "",
          nickname: "",
          password: "",
          rePassword: "",
          avatar: "",
        });

        http(
          `avatars`,
          {
            method: "POST",
            body: serialize({
                avatar: form.avatar,
            }),
          },
          true
        )
        .then((response) => {
          console.log(response)
          //localStorage.setItem("user", JSON.stringify({ ...response.data }))
        })
        .catch((error) => {
          console.error("avatar error:", error);
        });

        setSessionTime(3600);
        navigate("/seed");

      })
      .catch((error) => {

        setError(error);
        console.error("error signup:", error);

      });
  };

  const onCaptchaChange = async (value: string | null) => {
    if (value) {

        const requestOptions = {
          method: "POST",
          body: JSON.stringify({ recaptcha: { token: value } }),
        };

        http(`recaptcha`, requestOptions, false)
        .then((res) => {
        // tmp turn off captach
        // if (!res.success) throw res['error-codes'] ? res['error-codes'][0] : 'reCaptchaCheckFail';
          setIsCaptchaValid(true);
        })
        .catch((err) => {
          toast.error(t(err));
          setIsCaptchaValid(false);
        });
    };
  }

  return (
    <>
      <Layout>
        <WrapperStyles
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            maxWidth: "30rem",
            margin: "0 auto",
          }}
        >
          {isLoading ? (
            <div
              style={{
                position: "relative",
                top: "40%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Circles color="#818181" height={50} width={50} />
            </div>
          ) : (
            <>
              <PageTitleStyles>{t("registrationRequired")}</PageTitleStyles>
              <Input
                setState={setForm}
                value={""}
                label={t("avatar")}
                type={"avatar"}
                name={"avatar"}
                id={"avatar"}
                error={validationErrors.avatar ? true : false}
                errorMessage={
                  validationErrors.avatar && validationErrors.avatar
                }
              />
              <Input
                type={"nickname"}
                value={form.nickname}
                setState={setForm}
                label={t("nickname")}
                name={"nickname"}
                error={validationErrors.nickname ? true : false}
                errorMessage={
                  validationErrors.nickname && validationErrors.nickname
                }
              />
              <Input
                type={"password"}
                value={form.password}
                setState={setForm}
                label={t("password")}
                name={"password"}
                error={validationErrors.password ? true : false}
                errorMessage={
                  validationErrors.password && validationErrors.password
                }
              />
              <Input
                type={"password"}
                value={form.rePassword}
                setState={setForm}
                label={t("repeatPassword")}
                name={"rePassword"}
                error={validationErrors.rePassword ? true : false}
                errorMessage={
                  validationErrors.rePassword && validationErrors.password
                }
              />
              {error && (
                <BoxStyles mb={2} mt={0}>
                  <ErrorStyles>{t(error)}</ErrorStyles>
                </BoxStyles>
              )}
              <BoxStyles mb={4}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                  onChange={onCaptchaChange}
                  theme="light"
                />
              </BoxStyles>
              {isCaptchaValid && (
                <ButtonStyles onClick={handleSignUp}>{t("next")}</ButtonStyles>
              )}
              <FlexStyles alignItems="center" mt={3}>
                <BoxStyles mr={2}>
                  <BoldedTextStyles>{t("notNewUser")}</BoldedTextStyles>
                </BoxStyles>
                <LinkStyles onClick={() => navigate("/")}>
                  {t("singIn")}
                </LinkStyles>
              </FlexStyles>
            </>
          )}
        </WrapperStyles>
      </Layout>
    </>
  );
};

export default Register;
