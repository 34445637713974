import { FlexStyles } from "../../styles/FlexStyles";
import { BoxStyles } from "../../styles/BoxStyles";
import { StyledHrStyles } from "./styles";
import { useTranslation } from "react-i18next";

export const OrHr: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FlexStyles width="100%" justifyContent="center" alignItems="center">
      <BoxStyles width="100%" mr={2}>
        <StyledHrStyles />
      </BoxStyles>
      {t("or").toUpperCase()}
      <BoxStyles width="100%" ml={2}>
        <StyledHrStyles />
      </BoxStyles>
    </FlexStyles>
  );
};
