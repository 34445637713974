import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { validateEmail } from "./../helpers/helpers";
import { CustomRouteProps, ROUTES } from "./routes";

const RouterController: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route) => (
          <Route
            path={route.path}
            key={route.key}
            element={<RouteRenderer route={route} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

const RouteRenderer: React.FC<{ route: CustomRouteProps }> = ({ route }) => {
  return <>{checkRoute(route, {})}</>;
};

const checkRoute = (route: CustomRouteProps, props: any) => {
  const authenticated = !!localStorage.getItem("user-token");
  const authProcess = localStorage.getItem("authorisation");
  const params = new URLSearchParams(window.location.search);

  const redirectUrl = params.get("redirect_url");
  const userEmail = params.get("user_email");
  const authLevel = params.get("auth_level");

  if (redirectUrl) {
    localStorage.setItem("bussines_redirect_url", redirectUrl);
  }
  if (userEmail && validateEmail(userEmail)) {
    localStorage.setItem("bussines_user_email", userEmail);
  }
  if (authLevel) {
    localStorage.setItem("bussines_auth_level", authLevel);
  }

  if (authProcess) {
    if (route.path !== "/authorization" && authProcess === "email")
      return <Navigate to="/authorization" />;
    if (route.path !== "/authorization-sms" && authProcess === "phone")
      return <Navigate to="/authorization-sms" />;
  }

  if (route.private && !authenticated) return <Navigate to="/" />;
  //if (!route.private && authenticated) return <Navigate to="/account" />;

  return <route.component {...props} />;
};

export default RouterController;
