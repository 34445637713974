import styled, { css } from "styled-components";
import { BoxStyles, BoxStylesProps } from "../../styles/BoxStyles";

interface LabelStylesProps {
  htmlFor?: string;
  variant?: string;
  ml?: number;
}

interface InputStyledProps {
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  value?: string;
  theme: {
    colors: {
      textPrimary: string;
      backgroundPrimary: string;
      inputBorder: string;
      backgroundDisabled: string;
      error: string;
      textSuccess: string;
    };
  };
}

interface CheckStatusStylesProps {
  success?: boolean;
  theme: {
    colors: {
      textSuccess: string;
    };
  };
}

interface SelectStyledStylesProps {
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  theme: {
    colors: {
      textPrimary: string;
      backgroundPrimary: string;
      inputBorder: string;
      backgroundDisabled: string;
      error: string;
      textSuccess: string;
    };
  };
  value?: string;
}

interface CheckboxStyledProps {
  value?: string;
  checked?: boolean;
  error?: boolean;
  success?: boolean;
  theme: {
    colors: {
      buttonPrimaryBgc: string;
    };
  };
}

interface CheckTextStylesProps {
  block?: boolean;
}

interface InputSAvatartyledProps {
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  value?: string | boolean;
  testprop?: string;
}

interface SuccessMessageWrapperStylesProps extends BoxStylesProps {}

export const LabelStyles = styled.label<LabelStylesProps>`
  font-size: 1.4rem;
  display: block;

  /* Common font weight for all browsers */
  font-weight: 500;

  /* Firefox-specific font weight */
  @-moz-document url-prefix() {
    font-weight: 500; /* Change the font weight for Firefox */
  }

  white-space: nowrap;
`;

export const LabelSelectStyles = styled.label`
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: -10px;
`;

export const SmoothLabelStyles = styled.label`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const InputStyledStyles = styled.input<InputStyledProps>`
  height: 52px;
  width: 100%;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  ${({ theme, disabled, error, success }) => `
    border: 1px solid ${theme.colors.inputBorder};
    ${
      disabled &&
      css`
        cursor: not-allowed;
        background-color: ${theme.colors.backgroundDisabled};
      `
    }
    ${error && `border-color: ${theme.colors.error}`}
    ${success && `border-color: ${theme.colors.textSuccess}`}
  `};
  font-size: 1.6rem;
  padding: 0 24px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  :-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

// avatar input

export const InputStyledAvatarStyles = styled.input<InputSAvatartyledProps>`
  display: none;
`;

export const LabelAvatarStyles = styled.label`
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  white-space: nowrap;
`;

// phone input

export const PhoneInputStyledStyles = styled(InputStyledStyles)`
  position: relative;
  .iti__flag-container {
    display: none !important;
  }
`;

// checkbox

export const CheckboxStyledStyles = styled.input<CheckboxStyledProps>`
  opacity: 0;
  position: absolute;

  & + label {
    position: relative;
    content: "";
    margin: 0 12px 0 0;
    padding: 0;
    min-width: 26px;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    ${({ theme }) => `
      border: 1px solid ${theme.colors.inputBorder};
    `};
  }

  & + label:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 5px;
    left: 5px;
  }
  &:checked + label {
    border-color: ${({ theme }) => theme.colors.buttonPrimaryBgc};
  }
  &:checked + label:after {
    background: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHdpZHRoPSIzNjMuMDAwMDAwcHQiIGhlaWdodD0iMjk4LjAwMDAwMHB0IiB2aWV3Qm94PSIwIDAgMzYzLjAwMDAwMCAyOTguMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwyOTguMDAwMDAwKSBzY2FsZSgwLjEwMDAwMCwtMC4xMDAwMDApIgpmaWxsPSIjMDAwMDAwIiBzdHJva2U9Im5vbmUiPgo8cGF0aCBmaWxsPSIjQkIxNzNEIiBkPSJNMzA5NyAyOTU0IGMtNjMgLTE1IC0xMDYgLTM1IC0xNTMgLTY5IC0xNyAtMTMgLTM1NiAtNDM4IC03NTQgLTk0NAotMzk4IC01MDcgLTcyNiAtOTIxIC03MjkgLTkyMSAtNCAwIC0xNzMgMTYwIC0zNzYgMzU2IC0yMDQgMTk2IC0zOTAgMzY5IC00MTUKMzg1IC0yMzIgMTUyIC01NDggMzcgLTYzMCAtMjI4IC0zOSAtMTI2IC0yMiAtMjU1IDQ4IC0zNTYgNDAgLTU5IDEwNTggLTEwNDgKMTEzMSAtMTA5OSA3NCAtNTIgMTM2IC03MSAyMzEgLTcyIDEwMSAtMSAxOTIgMjcgMjU3IDc3IDIzIDE3IDQzMiA1MDggOTEwCjEwODkgNDc3IDU4MiA4ODcgMTA4MCA5MDkgMTEwNyA2NSA3NyA4OCAxNDYgODggMjY2IDAgMTMwIC0yMyAxOTEgLTEwNCAyNzkKLTY2IDcwIC0xMjkgMTA4IC0yMTUgMTMxIC03MSAxOCAtMTIxIDE4IC0xOTggLTF6Ii8+CjwvZz4KPC9zdmc+)
      no-repeat center center;
    background-size: contain;
  }
  &:checked + label:before {
    content: "";
    position: absolute;
    color: ${({ theme }) => theme.colors.textPrimary};
    width: 26px;
    height: 26px;
    top: 0;
    left: 0;
    border-radius: 8px;
  }
`;

export const CheckboxWrapperStyles = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  & label {
    cursor: pointer;
    text-align: justify;
    padding-left: 4px;
    margin-bottom: unset;
    ${({ theme }) => `
      color: ${theme.colors.textPrimary}
    `};
  }
`;

// common

export const InputWrapperStyles = styled.div`
  margin-bottom: 1.8rem;
  width: 100%;

  &:focus-within {
    ${InputStyledStyles} {
      border: 1px solid red;
    }
  }
`;

export const ErrorStyles = styled.span`
  font-size: 1.4rem;
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.error};
`;

// Status Input Wrapper

export const CheckStatusStyles = styled.span<CheckStatusStylesProps>`
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.textSuccess};
  opacity: ${({ success }) => (success ? 1 : 0)};
  position: relative;

  :after {
    content: "";
    background: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHdpZHRoPSIzNjMuMDAwMDAwcHQiIGhlaWdodD0iMjk4LjAwMDAwMHB0IiB2aWV3Qm94PSIwIDAgMzYzLjAwMDAwMCAyOTguMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwyOTguMDAwMDAwKSBzY2FsZSgwLjEwMDAwMCwtMC4xMDAwMDApIgpmaWxsPSIjMDAwMDAwIiBzdHJva2U9Im5vbmUiPgo8cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMzA5NyAyOTU0IGMtNjMgLTE1IC0xMDYgLTM1IC0xNTMgLTY5IC0xNyAtMTMgLTM1NiAtNDM4IC03NTQgLTk0NAotMzk4IC01MDcgLTcyNiAtOTIxIC03MjkgLTkyMSAtNCAwIC0xNzMgMTYwIC0zNzYgMzU2IC0yMDQgMTk2IC0zOTAgMzY5IC00MTUKMzg1IC0yMzIgMTUyIC01NDggMzcgLTYzMCAtMjI4IC0zOSAtMTI2IC0yMiAtMjU1IDQ4IC0zNTYgNDAgLTU5IDEwNTggLTEwNDgKMTEzMSAtMTA5OSA3NCAtNTIgMTM2IC03MSAyMzEgLTcyIDEwMSAtMSAxOTIgMjcgMjU3IDc3IDIzIDE3IDQzMiA1MDggOTEwCjEwODkgNDc3IDU4MiA4ODcgMTA4MCA5MDkgMTEwNyA2NSA3NyA4OCAxNDYgODggMjY2IDAgMTMwIC0yMyAxOTEgLTEwNCAyNzkKLTY2IDcwIC0xMjkgMTA4IC0yMTUgMTMxIC03MSAxOCAtMTIxIDE4IC0xOTggLTF6Ii8+CjwvZz4KPC9zdmc+)
      no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    color: ${({ theme }) => theme.colors.textPrimary};
    width: 12px;
    height: 12px;
    top: 6px;
    left: 6px;
    font-size: 14px;
  }
`;

export const CheckTextStyles = styled.p<CheckTextStylesProps>`
  margin-left: 32px;
  font-size: 1.4rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.textSuccess};
`;

// select

export const SelectStyledStyles = styled.select<SelectStyledStylesProps>`
  height: 47px;
  width: 100%;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  ${({ theme, disabled, error, success }) => `
    border: 1px solid ${theme.colors.inputBorder};
    ${
      disabled &&
      css`
        cursor: not-allowed;
        background-color: ${theme.colors.backgroundDisabled};
      `
    }
    ${error && `border-color: ${theme.colors.error}`}
    ${success && `border-color: ${theme.colors.textSuccess}`}
  `};
  font-size: 1.6rem;
  padding: 0 24px;
  margin-top: 1rem;
  margin-bottom: 0rem;
  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.inputBorder};
  :-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export const SelectWrapperStyles = styled.div`
  margin-bottom: 1.8rem;
  width: 100%;

  &:focus-within {
    ${SelectStyledStyles} {
      border: 1px solid red;
    }
  }
`;

export const SuccessMessageWrapperStyles = styled(
  BoxStyles
)<SuccessMessageWrapperStylesProps>``;
