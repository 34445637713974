import styled from "styled-components";
import { border, color, space, layout, position, system } from "styled-system";

export interface BoxStylesProps extends React.HTMLProps<HTMLDivElement> {
  m?: string;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  pb?: number;
  pr?: number;
  pt?: number;
  px?: number;
  fontSize?: string;
  minHeight?: string;
}

export const BoxStyles = styled.div<BoxStylesProps>`
  ${system({
    transform: true,
  })}
  ${border}
  ${color}
  ${layout}
  ${space}
  ${position}
`;
