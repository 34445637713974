import Layout from "../../components/Layout/Layout";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { PageCenterWrapperStyles } from "../../styles/WrapperStyles";
import { PageTitleStyles } from "../../styles/TypographyStyles";
import { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import { useTranslation } from "react-i18next";
import { http, RequestOptions } from "../../helpers/http";
import { ErrorStyles } from "../../components/ErrorMessage/styles";
import { parseURL } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";

const ResetPasswordComplete = () => {
  const params = parseURL();
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{
    errors: { reset_password_token: string[] };
  } | null>(null);
  const [form, setForm] = useState<Record<string, any>>({
    password: "",
    rePassword: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    password: "",
    rePassword: "",
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateInputs = () => {
    const errors: { password: string; rePassword: string } = {
      password: "Błąd w haśle",
      rePassword: "Błąd w powtórzeniu hasła",
    };
    if (!form.password.length) {
      errors.password = "requiredField";
    } else if (
      form.password.length &&
      form.rePassword.length &&
      form.password !== form.rePassword
    ) {
      errors.password = "differentPasswords";
      errors.rePassword = "differentPasswords";
    }
    if (!form.rePassword.length) {
      errors.rePassword = "differentPasswords";
    }
    if (form.password.length < 6) {
      errors.password = "passwordLength";
    }
    setValidationErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleNextStep = () => {
    if (validateInputs()) return;

    const requestOptions: RequestOptions = {
      method: "PATCH",
      body: JSON.stringify({
        user: {
          reset_password_token: params.token,
          password: form.password,
          password_confirmation: form.rePassword,
        },
      }),
    };

    http(`password`, requestOptions, true)
      .then(() => {
        setErrors(null);
        navigate("/?reset_password_complete=true");
      })
      .catch((err) => {
        if (err.message) setErrors(JSON.parse(err.message));
      });
  };

  const showHTTPError = () => {
    if (errors?.errors?.reset_password_token[0] === "is invalid") {
      return (
        <ErrorStyles style={{ textAlign: "center" }}>
          {t("resetPasswordTokenInvalid")}
        </ErrorStyles>
      );
    }
    if (
      errors?.errors?.reset_password_token[0] ===
      "has expired, please request a new one"
    ) {
      return (
        <ErrorStyles style={{ textAlign: "center" }}>
          {t("resetPasswordTokenExpired")}
        </ErrorStyles>
      );
    }
    return (
      <ErrorStyles style={{ textAlign: "center" }}>
        {t("anErrorOccurred")}
      </ErrorStyles>
    );
  };

  useEffect(() => {
    if (!params.token) navigate("/");
  }, [params]);

  return (
    <Layout>
      <PageCenterWrapperStyles maxWidth={30}>
        <PageTitleStyles>{t("recoverPassword")}</PageTitleStyles>
        {/*<PageDescription>*/}
        {/*  Możemy przesłać kod weryfikacyjny na podany numer celem ustanowienia nowego numeru PIN*/}
        {/*</PageDescription>*/}
        <Input
          type={"password"}
          value={form.password}
          setState={setForm}
          label={t("password") + "*"}
          name={"password"}
          error={validationErrors.password ? true : false}
          errorMessage={validationErrors.password && validationErrors.password}
        />
        <Input
          type={"password"}
          value={form.rePassword}
          setState={setForm}
          label={t("repeatPassword") + "*"}
          name={"rePassword"}
          error={validationErrors.rePassword ? true : false}
          errorMessage={
            validationErrors.rePassword && validationErrors.rePassword
          }
        />
        {errors && showHTTPError()}
        <ButtonStyles onClick={handleNextStep}>{t("next")}</ButtonStyles>
      </PageCenterWrapperStyles>
    </Layout>
  );
};

export default ResetPasswordComplete;
