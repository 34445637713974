import styled from "styled-components";

export const Grid2Styles = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 0 1.8rem;
  position: relative;
`;
export const StyleWrapperStyles = styled.div`
  input {
    height: 48px;
    border-radius: 8px;
    border-color: #cccccc;
    margin: 0;
  }
`;

export const CheckboxLabelStyles = styled.label`
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`;
