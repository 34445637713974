import { useState } from "react";
import {
  CheckboxStyledStyles,
  CheckboxWrapperStyles,
  InputStyledStyles,
  InputStyledAvatarStyles,
  InputWrapperStyles,
  LabelStyles,
  LabelAvatarStyles,
  ErrorStyles,
  SmoothLabelStyles,
  SelectStyledStyles,
  SelectWrapperStyles,
  LabelSelectStyles,
} from "./styles";
import { useTranslation } from "react-i18next";
import { BoxStyles } from "../../styles/BoxStyles";
import { FlexStyles } from "../../styles/FlexStyles";
import Avatar from "../Avatar/Avatar";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-solid.svg";

interface InputProps<T> {
  label: string | React.ReactNode;
  name: string;
  value?: string | boolean;
  setState?: React.Dispatch<React.SetStateAction<T>>;
  type: "checkbox" | "password" | "avatar" | "select" | string;
  disabled?: boolean;
  success?: boolean;
  toggleDisabled?: () => void;
  error?: boolean;
  errorMessage?: string;
  id?: string;
  smoothLabel?: boolean;
  options?: string[];
  placeholder?: string | null;
  className?: string;
  pattern?: any;
}

const Input: React.FC<InputProps<Record<string, any>>> = ({
  label,
  name,
  value,
  setState,
  type,
  disabled,
  success,
  toggleDisabled,
  error,
  errorMessage,
  id,
  smoothLabel = false,
  options = [],
  placeholder,
  className,
  pattern,
  ...props
}) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setState) {
      setState((state) => ({ ...state, [name]: event.target.value }));
    }
  };

  const changeCheckboxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setState) {
      setState((state) => ({
        ...state,
        [name]: event.target.value !== "true",
      }));
    }
  };

  const [isShown, setIsShown] = useState("password");

  const [image, setImage] = useState("");

  const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {});

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event?.target?.files && event.target.files[0] !== undefined && setState) {
        setImage(URL.createObjectURL(event.target.files[0]));
        //console.log(event.target.files[0])
        //@ts-ignore
        setState((state) => ({ ...state, [name]: event.target.files[0] }));
    }

  };
  /** POPRZEDNI KOD
  const onImageChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => setState((state) => ({ ...state, [name]: reader.result }));
};**/

  if (type === "checkbox") {
    return (
      <>
        <CheckboxWrapperStyles>
          <CheckboxStyledStyles
            id={id}
            name={name}
            value={value as string}
            onChange={changeCheckboxValue}
            type={type}
            checked={value as boolean}
            error={error as boolean}
            success={success}
            {...props}
          />
          <label htmlFor={name} />
          {smoothLabel ? (
            <SmoothLabelStyles htmlFor={name}>{label} </SmoothLabelStyles>
          ) : (
            <LabelStyles htmlFor={name}>{label}</LabelStyles>
          )}
        </CheckboxWrapperStyles>
        <BoxStyles mb={2}>
          {errorMessage && <ErrorStyles>{t(errorMessage)}</ErrorStyles>}
        </BoxStyles>
      </>
    );
  } else if (type === "password") {
    return (
      <InputWrapperStyles>
        <LabelStyles htmlFor={name}>{label}</LabelStyles>
        <InputStyledStyles
          id={id}
          name={name}
          value={value as string}
          onChange={changeValue}
          type={isShown}
          success={success}
          error={error}
          {...props}
        />
        <EyeIcon
          style={{
            marginTop: "-47px",
            marginBottom: "22px",
            marginLeft: "86%",
            height: "25px",
            width: "25px",
            display: "block",
          }}
          onClick={() =>
            isShown === "password" ? setIsShown("text") : setIsShown("password")
          }
        />
        {errorMessage && <ErrorStyles>{t(errorMessage)}</ErrorStyles>}
      </InputWrapperStyles>
    );
  } else if (type === "avatar") {
    return (
      <InputWrapperStyles>
        <LabelAvatarStyles htmlFor={name}>
          {label}
          <FlexStyles justifyContent={"center"}>
            <Avatar url={image} />
            <InputStyledAvatarStyles
              id={id}
              name={name}
              value={value as any}
              onChange={onImageChange}
              type={"file"}
              success={success}
              error={error}
              accept={"image/*"}
              testprop={image}
              {...props}
            />
          </FlexStyles>
        </LabelAvatarStyles>
        {errorMessage && <ErrorStyles>{t(errorMessage)}</ErrorStyles>}
      </InputWrapperStyles>
    );
  } else if (type === "select") {
    return (
      <SelectWrapperStyles>
        <LabelSelectStyles htmlFor={name}>{label}</LabelSelectStyles>
        <SelectStyledStyles
          id={id}
          name={name}
          value={value as string}
          onChange={changeValue as any}
          success={success}
          error={error}
          disabled={disabled}
          {...props}
        >
          <option value="">{t("selectValue")}</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </SelectStyledStyles>
        {errorMessage && <ErrorStyles>{t(errorMessage)}</ErrorStyles>}
      </SelectWrapperStyles>
    );
  } else {
    return (
      <InputWrapperStyles>
        <LabelStyles htmlFor={name}>{label}</LabelStyles>
        <InputStyledStyles
          id={id}
          name={name}
          value={value as string}
          onChange={changeValue}
          type={type}
          disabled={disabled}
          success={success}
          placeholder={placeholder || ''}
          error={error}
          pattern={pattern}
          {...props}
        />
        {errorMessage && <ErrorStyles>{t(errorMessage)}</ErrorStyles>}
      </InputWrapperStyles>
    );
  }
};

export default Input;
