import styled from "styled-components";

interface ButtonExtraSmallProps {
  secondary?: boolean;
}

export const ButtonStyles = styled.button`
  font-size: 1.4rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.buttonPrimaryFont};
  background-color: ${({ theme }) => theme.colors.buttonPrimaryBgc};
  border-radius: 10px;
  letter-spacing: 0.6px;
  padding: 1.5rem 2rem;
  width: 22rem;

  > svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
  }

  &[data-icon-right] {
    padding-right: 1.4rem;
    > svg {
      margin-left: 6px;
    }
  }
  &[data-icon-left] {
    padding-left: 1.4rem;
    > svg {
      margin-right: 6px;
    }
  }

  :hover {
    ${({ theme }) => `
      background-color: ${theme.colors.buttonSecondaryBgc};
    `}
  }
`;

export const ButtonSmallStyles = styled(ButtonStyles)`
  font-size: 1.2rem;
  padding: 1rem 2rem;
  width: 16rem;
`;
export const MnemonicButtonStyles = styled.span`
  display: inline-block;
  padding-left: 14px;
  font-weight: normal;
  padding-right: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 8px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  margin-right: 10px;
  cursor: pointer;
`;

export const ButtonExtraSmallStyles = styled(
  ButtonStyles
)<ButtonExtraSmallProps>`
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  width: 12rem;
`;
