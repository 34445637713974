import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import RICIBs from "react-individual-character-input-boxes";
import Layout from "../../components/Layout/Layout";
import {
  PageCenterWrapperStyles,
  RICIBsWrapperStyles,
  WrapperStyles,
} from "../../styles/WrapperStyles";
import {
  PageDescriptionStyles,
  PageTitleStyles,
  BoldedTextStyles,
} from "../../styles/TypographyStyles";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { ErrorStyles } from "../../components/ErrorMessage/styles";
import { useTranslation } from "react-i18next";
import { BoxStyles } from "../../styles/BoxStyles";
import { http } from "../../helpers/http";
import { ReactComponent as Envelope } from "../../assets/icons/envelope-open-text-solid.svg";
import Popup from "../../components/Popup/Popup";
import { FlexStyles } from "../../styles/FlexStyles";

const Authorization: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") || "");
  const phone = user?.phone ?? false;
  const phoneCode = localStorage.getItem("phoneCode");
  const confirmationStatus = localStorage.getItem("confirmed");
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [prevConfirmed, setPrevConfirmed] = useState(false);
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPOP, setShowPOP] = useState(false);
  const [timeStatus, setTimeStatus] = useState(60);

  useEffect(() => {
    timeStatus > 0 && setTimeout(() => setTimeStatus(timeStatus - 1), 1000);
  }, [timeStatus]);

  useEffect(() => {
    // if (!phone || !phoneCode) history.push('/');
    // confirmationStatus && confirmationStatus !== 'null' && history.push('/identification');
  }, [phone, phoneCode, navigate, confirmationStatus]);

  const parsePhone = (phone: string) => {
    let string = "";
    for (let i = 0; i < phone.length; i++) {
      string += i === 0 || i === phone.length - 1 ? phone[i] : "*";
    }
    return string;
  };

  const handleString = (output: string) => {
    setCode(output);
  };

  const handleClick = () => {
    // tmp
    // localStorage.setItem('confirmed', true);
    // history.push('/account');
    // tmp
    setLoading(true);
    setIncorrect(false);
    setPrevConfirmed(false);

    if (code.length !== 6) {
      setLoading(false);
      setIncorrect(true);
      return;
    }

    http(
      `phone-confirmation?phone_confirmation_token=${code}`,
      {
        method: "GET",
      },
      true
    )
      .then((response) => {
        localStorage.setItem("confirmed", true.toString());
        localStorage.removeItem("authorisation");

        if (localStorage.getItem("phone-chainging")) {
          localStorage.removeItem("phone-chainging");
          setShowPOP(true);
          setTimeout(function () {}, 5000);
          navigate("/");
        } else {
          setShowPOP(true);
          setTimeout(function () {
            navigate("/identification");
          }, 5000);
        }
      })
      .catch((err) => {
        const errorData = JSON.parse(err.message);
        if (
          errorData.phone_confirmation_token &&
          errorData.phone_confirmation_token[0] === "is invalid"
        )
          setIncorrect(true);
        if (
          errorData.email &&
          errorData.email[0] === "was already confirmed, please try signing in"
        )
          setPrevConfirmed(true);
        if (errorData.status && errorData.status === 500)
          setServerError("internal_error");
      })
      .finally(() => setLoading(false));
  };

  const handleResend = () => {
    setTimeStatus(60);

    setLoading(true);
    setIncorrect(false);
    setPrevConfirmed(false);

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ user: { phone } }),
    };

    http(`phone-confirmation`, requestOptions, true)
      .catch((err) => {
        const errorData = JSON.parse(err.message);
        if (errorData.error && errorData.error === "Phone not found")
          setIncorrect(true);
        if (
          errorData.error &&
          errorData.error === "Phone was already confirmed"
        )
          setPrevConfirmed(true);
        if (errorData.status && errorData.status === 500)
          setServerError("internal_error");
      })
      .finally(() => setLoading(false));
  };

  return showPOP === true ? (
    <WrapperStyles
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        maxWidth: "30rem",
        margin: "0 auto",
        fontSize: "14px",
      }}
    >
      <FlexStyles
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "rgb(125,125,125)",
          height: "100%",
          width: "100%",
          position: "fixed",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Popup
          icon={<Envelope style={{ marginTop: "10px" }} />}
          title={t("verificationCode")}
          description={t("ConfirmationVerificationCode2")}
          form={<ButtonStyles style={{ marginTop: "10px" }}>Ok</ButtonStyles>}
        />
      </FlexStyles>
    </WrapperStyles>
  ) : (
    <Layout>
      <PageCenterWrapperStyles maxWidth={30}>
        <PageTitleStyles>{t("enterSMSCode")}</PageTitleStyles>
        {phone && (
          <PageDescriptionStyles>
            {t("SMSCodeHasBeenSent")} {parsePhone(phone)}
          </PageDescriptionStyles>
        )}
        <RICIBsWrapperStyles incorrect={incorrect}>
          <RICIBs
            amount={6}
            autoFocus
            password
            inputProps={{
              style: {
                color: "black",
                border: "1px solid #CCCCCC",
                height: "48px",
                width: "36px",
                "font-size": "20px",
              },
            }}
            handleOutputString={handleString}
            inputRegExp={/^[a-zA-Z0-9]$/}
          />
        </RICIBsWrapperStyles>
        {incorrect && (
          <ErrorStyles style={{ textAlign: "center" }}>
            {t("invalidSMSCode")}
          </ErrorStyles>
        )}
        {prevConfirmed && (
          <>
            <ErrorStyles style={{ textAlign: "center", color: "black" }}>
              {t("phoneHasBeenVerified")}
            </ErrorStyles>
            <BoxStyles mt={1}>
              <ButtonStyles onClick={() => navigate("/")}>
                {t("login")}
              </ButtonStyles>
            </BoxStyles>
          </>
        )}
        {serverError && (
          <ErrorStyles style={{ textAlign: "center" }}>
            {t("serverError")}
          </ErrorStyles>
        )}
        {isLoading ? (
          <Circles color="#818181" height={50} width={50} />
        ) : (
          <>
            {!prevConfirmed && (
              <>
                <ButtonStyles onClick={handleClick}>{t("next")}</ButtonStyles>
                {timeStatus > 0 ? (
                  <BoxStyles
                    mt={3}
                    mb={3}
                    height={40}
                    width={40}
                    style={{
                      border: "2px solid black",
                      textAlign: "center",
                      lineHeight: "40px",
                      borderRadius: "50%",
                    }}
                  >
                    {timeStatus}
                  </BoxStyles>
                ) : (
                  <BoxStyles mt={3} mb={2}>
                    <BoldedTextStyles>
                      {t("lostCode")}{" "}
                      <a
                        style={{
                          color: "#CB004B",
                          textDecoration: "underline",
                        }}
                        onClick={handleResend}
                      >
                        {t("resend")}
                      </a>
                    </BoldedTextStyles>
                  </BoxStyles>
                )}
              </>
            )}
          </>
        )}
      </PageCenterWrapperStyles>
    </Layout>
  );
};

export default Authorization;
