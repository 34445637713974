import styled from "styled-components";

export const ModalWrapperStyles = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalStyles = styled.div`
  padding: 10rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTextStyles = styled.div`
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.backgroundPrimary};
  margin-bottom: 4rem;
`;

export const ButtonsWrapperStyles = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const ModalButtonStyles = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.buttonPrimaryFont};
  background-color: ${({ theme }) => theme.colors.buttonPrimaryBgc};
  border-radius: 0.8rem;
  letter-spacing: 0.6px;
  padding: 1rem 3rem;
  width: 8rem;
  :hover {
    background: #21884a;
  }
`;
