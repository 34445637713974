import { useMemo, useEffect, useState } from "react";
import {
  StyledWrapperStyles,
  StyledLinksListStyles,
  StyledLiStyles,
  ColoredPageDescriptionStyles,
  StyledAvatarStyles,
} from "./styles";
import { ReactComponent as XIcon } from "../../assets/icons/x_button.svg";
import { ReactComponent as UserSolidIcon } from "../../assets/icons/user-solid.svg";
import { ReactComponent as Authorization } from "../../assets/icons/authorization.svg";
import { ReactComponent as UserTieSolid } from "../../assets/icons/identification/user-tie-solid.svg";
import { ReactComponent as SignOutAltSolidIcon } from "../../assets/icons/sign-out-alt-solid.svg";
import { FlexStyles } from "../../styles/FlexStyles";
import { BoxStyles } from "../../styles/BoxStyles";
import { PageDescriptionStyles } from "../../styles/TypographyStyles";
import { logout } from "../../services/auth";
import NavLink from "../NavLink/NavLink";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { i18n } from "../../translations/i18n";
import {
  useGlobalStateContext,
  useGlobalStateDispatchContext,
} from "../../contexts/GlobalStateContext";

interface SidebarProps {
  setIsMenuOpened: React.Dispatch<boolean>;
}

const Sidebar: React.FC<SidebarProps> = ({ setIsMenuOpened }) => {
  const { setSessionTime, setIsHamburgerMenu } =
    useGlobalStateDispatchContext();
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
//  console.log(user)
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const { isHamburgerMenu } = useGlobalStateContext();
  const menuOptionsValues = useMemo(
    () => [
      {
        text: t("account"),
        path: "/account",
        icon: <UserSolidIcon />,
        key: 0,
      },
      {
        text: t("authorizations"),
        path: "/identification",
        icon: <Authorization />,
        key: 1,
      },
      {
        text: t("Kontomatik"),
        path: "/level-5",
        icon: <UserTieSolid />,
        key: 2,
      },
    ],
    [language]
  );

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <StyledWrapperStyles>
      <FlexStyles p={4} justifyContent={"end"}>
        <button
          onClick={() => {
            setIsMenuOpened(false);
          }}
        >
          <XIcon />
        </button>
      </FlexStyles>
      <FlexStyles>
        <FlexStyles m={2}>
          {
          user?.profile?.avatar || user?.avatar ? (
            <StyledAvatarStyles src={user?.profile?.avatar || user?.avatar} />
          ) : (
            <StyledAvatarStyles src="https://www.w3schools.com/howto/img_avatar.png" />
          )
          }
        </FlexStyles>
        <FlexStyles mt={1} ml={1} flexDirection="column" alignItems="start">
          <PageDescriptionStyles style={{ marginBottom: "0" }}>
            {user?.nickname}
          </PageDescriptionStyles>
          <PageDescriptionStyles style={{ marginBottom: "0" }}>
            {user?.profile?.first_name} {user?.profile?.last_name}
          </PageDescriptionStyles>
          <ColoredPageDescriptionStyles>
            {user.email ? user.email : user.unconfirmed_email}
          </ColoredPageDescriptionStyles>
        </FlexStyles>
      </FlexStyles>
      <BoxStyles px={3} mt={2} mb={1}>
        <hr></hr>
      </BoxStyles>
      <StyledLinksListStyles>
        {menuOptionsValues?.map(({ path, text, icon, key }) => (
          <StyledLiStyles key={key}>
            <NavLink to={path}>
              <FlexStyles>
                <BoxStyles mr={3} >{icon && icon}</BoxStyles> <BoxStyles style={{marginTop:'2px'}}>{text}</BoxStyles>
              </FlexStyles>
            </NavLink>
          </StyledLiStyles>
        ))}
        <BoxStyles pt={2} px={3}>
          <hr></hr>
        </BoxStyles>
        <FlexStyles mt={3} ml={3}>
          <button
            onClick={() => {
              setIsHamburgerMenu(!isHamburgerMenu);
              //changes done here in previous push, spliting push
              setSessionTime(0);
              logout(navigate);
            }}
            data-right
            data-icon-right
          >
            <FlexStyles>
              <BoxStyles mr={3}>
                <SignOutAltSolidIcon />
              </BoxStyles>
              <PageDescriptionStyles color="red">
                {t("logout")}
              </PageDescriptionStyles>
            </FlexStyles>
          </button>
        </FlexStyles>
      </StyledLinksListStyles>
    </StyledWrapperStyles>
  );
};

export default Sidebar;
