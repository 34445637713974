import React, { useContext, useState } from "react";
import { i18n } from "../translations/i18n";

const GlobalStateContext = React.createContext<
  | {
      lang: string;
      isHamburgerMenu: boolean;
      sessionTime: number;
    }
  | undefined
>(undefined);

const GlobalStateDispatchContext = React.createContext<
  | {
      setLang: (lang: string) => void;
      setIsHamburgerMenu: (isHamburgerMenu: boolean) => void;
      setSessionTime: (sessionTime: number) => void;
    }
  | undefined
>(undefined);

export const GlobalStateContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [lang, setLang] = useState("pl");
  const [isHamburgerMenu, setIsHamburgerMenu] = useState(false);
  const [sessionTime, setSessionTime] = useState(0);

  /**useEffect(() => {
    let timer: any;
    if (sessionTime > 0) {
      timer = setInterval(() => setSessionTime(prevSessionTime => prevSessionTime - 1), 1000);
    }
    if (sessionTime == 0) {
        console.log('loged out from global state context.tsx');
      window.localStorage.removeItem('user-token');
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('email');
    }
    return () => clearInterval(timer);
  }, [sessionTime]);**/

  return (
    <GlobalStateContext.Provider value={{ lang, isHamburgerMenu, sessionTime }}>
      <GlobalStateDispatchContext.Provider
        value={{
          setLang: (lang: string) => {
            setLang(lang);
            i18n.changeLanguage(lang);
          },
          setIsHamburgerMenu: (isHamburgerMenu: boolean) => {
            setIsHamburgerMenu(isHamburgerMenu);
          },
          setSessionTime: (sessionTime: number) => {
            setSessionTime(sessionTime);
          },
        }}
      >
        {children}
      </GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalStateContext = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error(
      "useGlobalStateContext must be inside a GlobalStateContextProvider with a value"
    );
  }
  return context;
};

export const useGlobalStateDispatchContext = () => {
  const context = useContext(GlobalStateDispatchContext);
  if (!context) {
    throw new Error(
      "useGlobalStateDispatchContext must be inside a GlobalStateDispatchProvider with a value"
    );
  }
  return context;
};
