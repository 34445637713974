type KontomatikOptions = {
  divId?: string;
  client?: string;
  locale?: string;
  country?: string;
  ownerExternalId?: string;
  showFavicons?: boolean;
  showScreenshots?: boolean;
  layout?: string;
  target?: string;
  widgetEmbeddedUrl?: string;
  styles?: string;
  autoImport?: boolean;
  showTargetMissingOption?: boolean;
  showDefaultTarget?: boolean;
  showBetaQualityLabels?: boolean;
  showTargetMissingForm?: boolean;
  clientIdentity?: string;
  dynamicHeight?: boolean;
  psd2?: boolean;
  forceTestCredentials?: boolean;
  [key: string]: any;
  onError?: (cause: string, details: any) => void;
  onSuccess?: (
    target: string,
    sessionId: string,
    sessionIdSignature: string,
    options: any
  ) => void;
  onUnsupportedTarget?: (details: any) => void;
  onInitialized?: (details: any) => void;
  onUnloaded?: (details: any) => void;
  onTargetSelected?: (details: any) => void;
  onCredentialEntered?: () => void;
  onStarted?: () => void;
  onConsentsGiven?: () => void;
  onRedirect?: () => void;
  onReset?: () => void;
  onExternalLink?: (url: string) => void;
};

type AnyObject = Record<string, any>;

const KontomatikPrivateUtils = {
  Objects: {
    pushNonBlank: (array: any[], elm: any) => {
      if (elm) array.push(elm);
    },

    stringify: (
      object: AnyObject,
      opts: { maxDepth?: number },
      depth: number = 1,
      prefix: string = ""
    ) => {
      opts = opts || {};
      opts.maxDepth = opts.maxDepth || 5;
      if (depth > opts.maxDepth + 1 || object === null || object === undefined)
        return "";

      if (
        typeof object === "string" ||
        typeof object === "number" ||
        typeof object === "boolean"
      )
        return prefix
          ? encodeURIComponent(prefix) + "=" + encodeURIComponent(object)
          : "";

      const parts: string[] = [];
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          KontomatikPrivateUtils.Objects.pushNonBlank(
            parts,
            KontomatikPrivateUtils.Objects.stringify(
              object[key],
              opts,
              depth + 1,
              prefix ? `${prefix}[${key}]` : key
            )
          );
        }
      }

      return parts.join("&");
    },
  },
  hash: (string: string) => {
    let h = 0xdeadbeef;
    for (let i = 0; i < string.length; i++) {
      h = Math.imul(h ^ string.charCodeAt(i), 2654435761);
    }
    return (h ^ (h >>> 16)) >>> 0;
  },
};

export const embedKontomatik = (opts: KontomatikOptions) => {
  var clientsWithoutMaxIframeWidth = [3540102936, 1748939436];
  var HOST = "https://signin.kontomatik.com";
  var options: KontomatikOptions = opts;

  const embedWidget = () => {
    var error = checkForEmbedErrors(options);
    if (error) {
      alert(error);
      throw new Error(error);
    }
    return doEmbedWidget();
  };

  const checkForEmbedErrors = (options: KontomatikOptions) => {
    if (!document.getElementById(options.divId || "")) {
      return (
        'Unable to embed widget. Reason: missing element with id="' +
        options.divId +
        '"'
      );
    }

    if (!options.client) {
      return 'Unable to embed widget. Reason: missing "client" param';
    }

    return "";
  };

  const doEmbedWidget = () => {
    window.addEventListener("message", callback, false);

    const widget = createIframeElement() as HTMLIFrameElement & {
      notifyPopupClosed?: () => void;
      removeEventListeners?: () => void;
    };

    injectWidgetHtml(widget);
    widget.notifyPopupClosed = () => {
      widget.contentWindow?.postMessage(
        JSON.stringify({
          kontomatik: {
            notify: "popupClosed",
          },
        }),
        "*"
      );
    };
    widget.removeEventListeners = removeEventListeners;
    return widget;
  };

  const injectWidgetHtml = (widget: HTMLIFrameElement) => {
    var anchor = document.getElementById(options.divId || "");

    if (anchor) {
      // Clear all child nodes
      anchor.innerHTML = "";

      // Append iframe
      anchor.appendChild(widget);
    }
  };

  const createIframeElement = () => {
    var el = document.createElement("iframe");

    el.src = buildUrl(options);
    el.style.width = "100%";
    el.style.cssText += " min-width: 280px !important;";
    el.style.margin = "0";
    el.style.padding = "0";
    el.style.border = "none";
    el.style.overflow = "hidden";
    el.scrolling = "no";
    if (
      !options.layout &&
      !clientsWithoutMaxIframeWidth.includes(
        KontomatikPrivateUtils.hash(options.client || "")
      )
    )
      el.style.maxWidth = "400px";
    if (!options.dynamicHeight) {
      el.height = "560";
      el.style.height = "560px";
    }

    el.setAttribute(
      "sandbox",
      "allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-scripts allow-same-origin allow-downloads"
    );

    return el;
  };

  const buildUrl = (options: KontomatikOptions) => {
    var parameters: Record<string, any> = {};
    var names = [
      "client",
      "locale",
      "country",
      "ownerExternalId",
      "showFavicons",
      "showScreenshots",
      "layout",
      "target",
      "widgetEmbeddedUrl",
      "styles",
      "autoImport",
      "showTargetMissingOption",
      "showDefaultTarget",
      "showBetaQualityLabels",
      "showTargetMissingForm",
      "clientIdentity",
      "dynamicHeight",
      "psd2",
      "forceTestCredentials",
    ];
    for (var i = 0; i < names.length; i++)
      if (options.hasOwnProperty(names[i]))
        parameters[names[i]] = options[names[i]];
    return (
      baseUrl() +
      KontomatikPrivateUtils.Objects.stringify(parameters, {}, 0, "")
    );
  };

  const baseUrl = () => {
    return HOST + "/signin/?";
  };

  const parseJson = (text: any) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      return {};
    }
  };

  const callback = (event: MessageEvent) => {
    if (event.origin !== HOST) return;
    var json =
      typeof event.data === "string" ? parseJson(event.data) : event.data;

    if (!json.kontomatik) return;

    if (json.kontomatik.error) callOnError(json.kontomatik);
    else if (json.kontomatik.unsupportedTarget)
      callOnUnsupportedTarget(json.kontomatik);
    else if (json.kontomatik.application)
      callOnApplicationStateChanged(json.kontomatik);
    else if (json.kontomatik.targetSelected)
      callOnTargetSelected(json.kontomatik);
    else if (json.kontomatik.credentialEntered) callOnCredentialEntered();
    else if (json.kontomatik.started) callOnStarted();
    else if (json.kontomatik.consentsGiven) callOnConsentsGiven();
    else if (json.kontomatik.redirect) callOnRedirect();
    else if (json.kontomatik.reset) callOnReset();
    else if (json.kontomatik.url) callOnExternalLink(json.kontomatik);
    else if (json.kontomatik.widgetSize) resizeWidget(json.kontomatik);
    else if (json.kontomatik.signedIn) {
      removeEventListeners();
      callOnSuccess(json.kontomatik);
    }
  };

  const callOnError = (json: any) => {
    options.onError &&
      options.onError(json.error.cause, {
        target: json.error.target,
        sessionId: json.error.sessionId,
        message: json.error.message,
      });
  };

  const callOnSuccess = (json: any) => {
    options.onSuccess &&
      options.onSuccess(
        json.signedIn.target,
        json.signedIn.sessionId,
        json.signedIn.sessionIdSignature,
        json.signedIn.options
      );
  };

  const callOnUnsupportedTarget = (json: any) => {
    options.onUnsupportedTarget &&
      options.onUnsupportedTarget({
        target: json.unsupportedTarget.target,
        country: json.unsupportedTarget.country,
        address: json.unsupportedTarget.address,
      });
  };

  const callOnApplicationStateChanged = (json: any) => {
    if (options.onInitialized && json.application.state === "widgetInitialized")
      options.onInitialized({
        application: {
          state: json.application.state,
        },
      });

    if (options.onUnloaded && json.application.state === "unloaded")
      options.onUnloaded({
        application: {
          state: json.application.state,
        },
      });
  };

  const callOnTargetSelected = (json: any) => {
    options.onTargetSelected && options.onTargetSelected(json.targetSelected);
  };

  const callOnCredentialEntered = () => {
    options.onCredentialEntered && options.onCredentialEntered();
  };

  const callOnStarted = () => {
    options.onStarted && options.onStarted();
  };

  const callOnConsentsGiven = () => {
    options.onConsentsGiven && options.onConsentsGiven();
  };

  const callOnRedirect = () => {
    options.onRedirect && options.onRedirect();
  };

  const callOnReset = () => {
    options.onReset && options.onReset();
  };

  const callOnExternalLink = (json: any) => {
    options.onExternalLink && options.onExternalLink(json.url);
  };

  const resizeWidget = (json: any) => {
    if (options.dynamicHeight) {
      var widgetSize = json.widgetSize || {};
      var anchor = document.querySelector(
        `#${options.divId} iframe[src^="${baseUrl()}"]`
      ) as HTMLIFrameElement;
      if (widgetSize.height && anchor) anchor.height = widgetSize.height;
    }
  };

  const removeEventListeners = () => {
    window.removeEventListener("message", callback, false);
  };

  options = opts;
  if (!options.divId) options.divId = "kontomatik";
  if (window.location) options.widgetEmbeddedUrl = window.location.href;
  return embedWidget();
};
