import Layout from "../../components/Layout/Layout";
import { FlexStyles } from "../../styles/FlexStyles";
import { BoxStyles } from "../../styles/BoxStyles";
import { PageTitleStyles } from "../../styles/TypographyStyles";

export const TermsAndConditions: React.FC = () => {
  return (
    <Layout>
      <PageTitleStyles>
        Ustawienia Zaawansowane Polityki Prywatności
      </PageTitleStyles>
      <FlexStyles px={20}>
        <BoxStyles width={8 / 10} pr={20}>
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
          Lorem ipsum dolor sit amet, consectetur adip Lorem ipsum dolor sit
          amet, consectetur adip Lorem ipsum dolor sit amet, consectetur adip
        </BoxStyles>
        <FlexStyles
          mt={10}
          pl={2}
          width={2 / 10}
          ml={10}
          flexDirection="column"
        >
          {/* <ToggleInput />
          <ToggleInput />
          <ToggleInput /> */}
        </FlexStyles>
      </FlexStyles>
    </Layout>
  );
};
