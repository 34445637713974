import { useEffect, useState } from "react";
import { ModalStyles } from "../../styles/ModalStyles";
import {
  LinkStyles,
  PageTitleStyles,
  PageDescriptionStyles,
} from "../../styles/TypographyStyles";
import { FlexStyles } from "../../styles/FlexStyles";
import { ButtonExtraSmallStyles } from "../../styles/ButtonStyles";
import { BoxStyles } from "../../styles/BoxStyles";
import { ContentStyles } from "../../styles/ContentStyles";
import { useTranslation } from "react-i18next";

const Level5Tutorial: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const content = [
    ["", t("tutorial"), t("tutorial2")],
    [<span>01</span>, t("tutorial3"), t("tutorial4")],
    [<span>02</span>, t("tutorial5"), t("tutorial6")],
    [<span>03</span>, t("tutorial7"), t("tutorial8")],
    [<span>04</span>, t("tutorial9"), t("tutorial10")],
    [<span>05</span>, t("tutorial11"), t("tutorial12")],
  ];
  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    if (!isOpen) localStorage.setItem("user-level5-tutorial", "true");
  }, [isOpen]);

  return (
    <ModalStyles style={{paddingTop: '10px'}} isOpen={isOpen} onCancelClick={() => setIsOpen(false)}>
      <ContentStyles >
        <div>
          <PageTitleStyles>
            {content[contentIndex][0]}
            {content[contentIndex][1]}
          </PageTitleStyles>
          <BoxStyles mt={4} px={2}>
            <PageDescriptionStyles>
              {content[contentIndex][2]}
            </PageDescriptionStyles>
          </BoxStyles>
        </div>
        <div>
          <FlexStyles justifyContent="space-around">
            <ButtonExtraSmallStyles
              onClick={() => {
                if (content[contentIndex - 1])
                  setContentIndex(contentIndex - 1);
              }}
              secondary
            >
              {t("back")}
            </ButtonExtraSmallStyles>
            <ButtonExtraSmallStyles
              onClick={() => {
                if (content[contentIndex + 1]) {
                  setContentIndex(contentIndex + 1);
                } else {
                  setIsOpen(false);
                }
              }}
            >
              {t("next")}
            </ButtonExtraSmallStyles>
          </FlexStyles>
          <LinkStyles
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            {t("skip")}
          </LinkStyles>
        </div>
      </ContentStyles>
    </ModalStyles>
  );
};

export default Level5Tutorial;
