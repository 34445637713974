import styled from "styled-components";
import { PageDescriptionStyles } from "../../styles/TypographyStyles";

export const StyledWrapperStyles = styled.nav`
  z-index: 1000;
  margin: 0;
  padding: 0;
  width: 250px;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  position: fixed;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const StyledLinksListStyles = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const StyledLiStyles = styled.li`
  display: flex;
  a path {
    fill: #271d30;
  }
`;

export const ColoredPageDescriptionStyles = styled(PageDescriptionStyles)`
  color: red;
  font-size: 1.2rem;
`;

export const StyledAvatarStyles = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50px;
`;
