import styled from "styled-components";

interface ModalStylesProps {
  isOpen: boolean;
  onCancelClick: () => void;
}

export const ModalStyles = styled.div<ModalStylesProps>`
  padding: 10rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalButtonStyles = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.buttonPrimaryFont};
  background-color: ${({ theme }) => theme.colors.buttonPrimaryBgc};
  border-radius: 0.8rem;
  letter-spacing: 0.6px;
  padding: 1rem 3rem;
  width: 8rem;
  :hover {
    background: #21884a;
  }
`;

export const ModalTextStyles = styled.div`
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.backgroundPrimary};
  margin-bottom: 4rem;
`;
