import { FC } from "react";
import Layout from "../../components/Layout/Layout";
import {
  PageCenterWrapperStyles,
  SingleBoxesWrapperStyles,
} from "../../styles/WrapperStyles";
import {
  PageTitleStyles,
  SmallTitleStyles,
} from "../../styles/TypographyStyles";
import { ButtonStyles } from "../../styles/ButtonStyles";
import RICIBs from "react-individual-character-input-boxes";
import { useTranslation } from "react-i18next";

const SetPin: FC = () => {
  function handleString() {
    console.log("a");
  }

  const { t } = useTranslation();

  return (
    <Layout>
      <PageCenterWrapperStyles maxWidth={30}>
        <PageTitleStyles>{t("setPIN")}</PageTitleStyles>
        <SingleBoxesWrapperStyles>
          <SmallTitleStyles>{t("enterPIN")}</SmallTitleStyles>
          <RICIBs
            inputProps={{
              style: {
                width: "36px",
                height: "48px",
                border: "1px solid #CCCCCC",
                color: "#271D30",
                "font-size": "22px",
                "border-radius": "8px",
              },
            }}
            amount={4}
            autoFocus
            inputRegExp={/^[0-9]$/}
            password
            handleOutputString={handleString}
          />
        </SingleBoxesWrapperStyles>
        <SingleBoxesWrapperStyles>
          <SmallTitleStyles>{t("repeatPinCode")}</SmallTitleStyles>
          <RICIBs
            inputProps={{
              style: {
                width: "36px",
                height: "48px",
                border: "1px solid #CCCCCC",
                color: "#271D30",
                "font-size": "22px",
                "border-radius": "8px",
                "margin-bottom": "32px",
              },
            }}
            amount={4}
            autoFocus
            inputRegExp={/^[0-9]$/}
            password
            handleOutputString={handleString}
          />
        </SingleBoxesWrapperStyles>
        <ButtonStyles>{t("next")}</ButtonStyles>
      </PageCenterWrapperStyles>
    </Layout>
  );
};

export default SetPin;
