import styled from "styled-components";

const primaryColor = "#34568B";
const secondaryColor = "#FFFFFF";
const textColor = "#333333";

export const WrapperStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${secondaryColor};
`;

export const ContentWrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px;
  background-color: #fff;
`;

export const ImageStyles = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    max-width: 40%;
`;

export const TitleStyles = styled.h1`
  font-size: 40px;
  color: ${primaryColor};
  margin-top: 20px;
  margin-bottom: 0;
`;

export const SubtitleStyles = styled.h2`
  font-size: 80px;
  color: ${primaryColor};
  margin-top: 0;
  margin-bottom: 0;
`;

export const DescriptionStyles = styled.p`
  font-size: 18px;
  color: ${textColor};
  margin-top: 10px;
`;

export const ButtonStyles = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 15px 20px;
  background-color: ${primaryColor};
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fff;
    color: ${primaryColor};
    border: 2px solid ${primaryColor};
  }
`;
