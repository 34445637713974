import { useState, useEffect } from "react";
import { withTheme, ThemedStyledProps } from "styled-components";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { WrapperStyles } from "../../styles/WrapperStyles";
import {
  PageTitleStyles,
  PageErrorStyles,
} from "../../styles/TypographyStyles";
import IdentificationCard from "../../components/IdentificationCard/IdentificationCard";
import { GridForCardStyles } from "../../components/IdentificationCard/styles";
import Alert from "../../components/Alert/Alert";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { TopButtonsWrapperStyles } from "./styles";
import Popup from "../../components/Popup/Popup";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { ReactComponent as LogoChain } from "../../assets/icons/identification/link-solid.svg";
import { ReactComponent as LogoIdCard } from "../../assets/icons/identification/bx_id-card.svg";
import { ReactComponent as LogoPhone } from "../../assets/icons/identification/carbon_phone-filled.svg";
import { ReactComponent as LogoMail } from "../../assets/icons/identification/at-solid.svg";
import { ReactComponent as LogoCreditCard } from "../../assets/icons/identification/fa-regular_credit-card.svg";
import { ReactComponent as LogoAccountTie } from "../../assets/icons/identification/mdi_account-tie.svg";
import { ReactComponent as UserSolid } from "../../assets/icons/identification/user-solid.svg";
import { ReactComponent as UserTieSolid } from "../../assets/icons/identification/user-tie-solid.svg";
import { ReactComponent as LogoFB } from "../../assets/icons/identification/akar-icons_facebook-fill.svg";
import { ReactComponent as LogoCamera } from "../../assets/icons/identification/ic_sharp-photo-camera.svg";
import { ReactComponent as LogoAccountCircle } from "../../assets/icons/identification/ic_sharp-account-circle.svg";
import { Theme } from "../../styles/theme/theme";

interface IdentificationProps {}

const Identification: React.FC<
  ThemedStyledProps<IdentificationProps, Theme>
> = ({ theme }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const confirmationStatus = localStorage.getItem("confirmed");
  const [verified, setVerified] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [background, setBackground] = useState("#E63434");
  const [fullAccount, setFullAccount] = useState(false);
  const [bussinesRedirectAlert, setBussinesRedirectAlert] = useState(false);
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState("");
  const [identifications, setIdentifications] = useState([
    { name: "nickname", href: "", img: LogoIdCard, confirmed_at: '' },
    { name: "address", href: "", img: LogoMail, confirmed_at: false },
    { name: "phoneNumber", href: "", img: LogoPhone, confirmed_at: false },
    {
      name: "identificationWallet",
      href: "",
      img: LogoChain,
      confirmed_at: false,
    },
    { name: "notary", href: "", img: LogoAccountTie, confirmed_at: false },
    { name: "nameAndSurname", href: "", img: UserSolid, confirmed_at: false },
    {
      name: "idCardNumber",
      href: "/level-5",
      img: LogoCreditCard,
      confirmed_at: false,
    },
    { name: "idCardDate", href: "", img: LogoCreditCard, confirmed_at: false },
    { name: "AddresMain", href: "", img: LogoCreditCard, confirmed_at: false },
    {
      name: "AddresTemporary",
      href: "",
      img: LogoCreditCard,
      confirmed_at: false,
    },
    {
      name: "AddresNormal",
      href: "",
      img: LogoCreditCard,
      confirmed_at: false,
    },
    /**{ name: 'photoAuthorization', href: '', img: LogoCamera, confirmed_at: false },
    { name: 'bankAuthorization', href: '/level-5', img: LogoCreditCard, confirmed_at: true },
    { name: 'blockChain', href: '', img: LogoChain, confirmed_at: false },
    { name: 'notary', href: '', img: LogoAccountTie, confirmed_at: false },**/
  ]);

  const fetchAccount = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Access-Control-Allow-Origin": "*" },
    };

    http(`users/me`, requestOptions, true)
      .then((response) => {
        let verification = response.data.verification_status;

        let user = response.data.profile;

        if (user.first_name && user.last_name) {
          if (
            localStorage.getItem("bussines_redirect_url") &&
            localStorage.getItem("bussines_auth_level")
          ) {
            if (//@ts-ignore
              localStorage.getItem("bussines_auth_level") <= response.data.authorization_level
            ) {
              setBussinesRedirectAlert(true);
            }
          }
        }

        if (verification === "completed") {
          setBackground("#21884A");
          setVerified(true);
          localStorage.setItem("verified", verification);
        } else if (verification === "failed") {
          setWarning(true);
        } else {
          setBackground("#E63434");
        }
        if (
          user.first_name &&
          user.last_name &&
          user.birth_date &&
          user.pesel &&
          user.street &&
          user.street_number &&
          user.zip_code &&
          user.city &&
          user.country
        ) {
          setFullAccount(true);
        } else {
          // todo after pesel and birthday
          // setFullAccount(false);
          setFullAccount(true);
        }

        const identificationsUpdate = [...identifications];
        if (response.data.phone_confirmed_at) {
          const index = identificationsUpdate.findIndex(
            (el) => el.name === "phoneNumber"
          );
          identificationsUpdate[index] = {
            name: "phoneNumber",
            href: "",
            img: LogoPhone,
            confirmed_at: response.data.phone_confirmed_at,
          };
        }

        if (response.data.confirmed_at) {
          const index = identificationsUpdate.findIndex(
            (el) => el.name === "address"
          );
          identificationsUpdate[index] = {
            name: "address",
            href: "",
            img: LogoMail,
            confirmed_at: response.data.confirmed_at,
          };
        }

        if (response.data.created_at) {
          const index = identificationsUpdate.findIndex(
            (el) => el.name === "nickname"
          );
          identificationsUpdate[index] = {
            name: "nickname",
            href: "",
            img: LogoIdCard,
            confirmed_at: response.data.created_at,
          };
        }


        if (response.data.verification_institution) {

          response.data.last_verifications.map((verification: any) => {
            if(verification.name == 'bank_account' && verification.status == 'completed'){
              const index = identificationsUpdate.findIndex(
                (el) => el.name === "nameAndSurname"
              );
              identificationsUpdate[index] = {
                name: "nameAndSurname",
                href: "",
                img: LogoIdCard,
                confirmed_at: verification.finished_at,
              };
            }
          });

        }

        setIdentifications(identificationsUpdate);
      })
      .catch((err) => {
        console.log(err);
        setError(t("serverError"));
      });
  };

  useEffect(() => {
    // !user && history.push('/');
    // confirmationStatus === 'null' && user && history.push('/authorization');
    fetchAccount();
  }, [user, navigate, confirmationStatus]);

  return (
    <>
      <Layout
        hideLogo
        logoutButton
        menuButton
        menuTitle={t("authorization")}
        bgc={theme.colors.backgroundSecondary}
      >
        {error ? (
          <PageErrorStyles>{error}</PageErrorStyles>
        ) : (
          <WrapperStyles
            style={{ margin: "auto", maxWidth: "900px", paddingBottom: 40 }}
          >
            <PageTitleStyles
              style={{
                textAlign: "left",
                marginBottom: "0",
                paddingLeft: "20px",
              }}
            >
              {t("accountForAuthorization")}
            </PageTitleStyles>
            <TopButtonsWrapperStyles>
              <IdentificationCard
                account
                text={t("personalAccount")}
                Icon={UserSolid}
                href={"/account"}
              />
              <IdentificationCard
                account
                text={t("organization")}
                Icon={UserTieSolid}
                href={"/account"}
              />
            </TopButtonsWrapperStyles>

            <PageTitleStyles
              mt={0}
              style={{ textAlign: "left", paddingLeft: "20px" }}
            >
              {t("authlevel")}
            </PageTitleStyles>
            <GridForCardStyles>
              {identifications.map((identificator, idx) => (
                <IdentificationCard
                  key={idx}
                  confirmed_at={identificator.confirmed_at}
                  title={`${t("level")} ${idx}`}
                  Icon={identificator.img}
                  text={identificator.name}
                  //href={fullAccount ? identificator.href : '#'}
                  //onClick={() => !fullAccount && setAlertVisible(true)}
                  warning={idx === 5 && warning}
                />
              ))}
            </GridForCardStyles>
            {alertVisible && (
              <Alert ok onClickOk={() => setAlertVisible(false)}>
                {t("completeAccountAlert")}
              </Alert>
            )}
            {bussinesRedirectAlert && (
              <div className="overlay_acc">
                <Popup
                  title={t("bussinesDescription")}
                  description={t("bussinesDescription2")}
                  form={
                    <>
                      <ButtonStyles
                        onClick={() =>
                          setBussinesRedirectAlert(!bussinesRedirectAlert)
                        }
                      >
                        {" "}
                        {t("Anuluj")}
                      </ButtonStyles>{" "}
                      <ButtonStyles
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          localStorage.removeItem('bussines_redirect_url');
                          window.location.replace(
                            localStorage.getItem("bussines_redirect_url") || ""
                          );
                        }}
                      >
                        {" "}
                        {t("next")}
                      </ButtonStyles>
                    </>
                  }
                />
              </div>
            )}
          </WrapperStyles>
        )}
      </Layout>
    </>
  );
};

export default withTheme(Identification);
