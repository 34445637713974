import styled from "styled-components";
import { space } from "styled-system";

interface PageTitleStylesProps {
  mt?: number;
  mb?: number;
}

export const PageTitleStyles = styled.div<PageTitleStylesProps>`
  font-size: 24px;
  text-align: center;
  margin-bottom: 22px;
  font-weight: bold;
  color: ${({ theme, color }) => (color ? color : theme.colors.textPrimary)};
  ${space};
`;
export const SmallTitleStyles = styled.div`
  font-size: 14px;
  text-align: left;
  width: 100%;
  margin-top: 29px;
  padding-left: 8px;
  color: ${({ theme, color }) => (color ? color : theme.colors.textPrimary)};
  ${space};
`;
export const MediumTitleStyles = styled.div`
  font-size: 18px;
  text-align: left;
  padding-left: 10%;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${({ theme, color }) => (color ? color : theme.colors.textPrimary)};
  ${space};
`;
export const PageDescriptionStyles = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 1.4rem;
  color: ${({ theme, color }) => (color ? color : theme.colors.textPrimary)};
`;

export const PageSuccessStyles = styled.div`
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.colors.textSuccess};
`;

export const PageErrorStyles = styled.div`
  font-size: 1.6rem;
  text-align: center;
  margin-top: 10rem;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const LinkStyles = styled.a`
  color: ${({ theme }) => theme.colors.link};
  font-weight: 500;
  font-size: 1.4rem;
  text-decoration: underline;
  margin-bottom: 1.4rem;
  cursor: pointer;
  margin-top: 1.4rem;
`;

export const ResendStyles = styled.button`
  color: ${({ theme }) => theme.colors.textThird};
  font-weight: 700;
  font-size: 1.4rem;
  text-decoration: underline;
  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
  box-shadow: none;
  outline: none;
  border: none;
`;

export const BoldedTextStyles = styled.span`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 1.4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textPrimary};
`;
