import styled from "styled-components";

interface LogoStylesProps {
  readonly $height: number;
}

export const CenterWrapperStyles = styled.header`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
  padding-top: 2rem;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  button[data-right] {
    right: 20px;
  }
  button[data-left] {
    left: 20px;
  }
  button {
    position: absolute;
    top: 20px;
    width: fit-content;
    max-width: 180px;
  }

  span {
    position: absolute;
    top: 21px;
    font-size: 15px;
    fotn-family: Avenir;
    font-weight: bold;
  }
`;

export const LogoStyles = styled.img<LogoStylesProps>`
  height: ${({ $height }) => $height + "rem"};
  width: auto;
`;
