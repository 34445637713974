import React, { useEffect, useRef } from "react";
import {
  PhoneInputStyledStyles,
  InputWrapperStyles,
  LabelStyles,
  ErrorStyles,
} from "./styles";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import {
  isValidPhoneNumber,
  AsYouType,
  getExampleNumber,
  CountryCode,
} from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import { useTranslation } from "react-i18next";

interface PhoneInputProps<T> {
  label: string;
  name: string;
  value: string;
  language: {
    iso2: string;
  };
  setState?: React.Dispatch<React.SetStateAction<T>>;
  type: string;
  error: boolean;
  errorMessage?: string;
  hidePhoneCode?: boolean;
  selectCountryOnly?: boolean;
  countries?: string[];
  langName?: string;
}

const PhoneInput: React.FC<PhoneInputProps<Record<string, any>>> = ({
  label,
  name,
  value,
  language,
  setState,
  type,
  error,
  errorMessage,
  hidePhoneCode,
  selectCountryOnly,
  countries = [],
  langName,
}) => {
  const isMobile = window.mobileAndTabletCheck();
  const { t } = useTranslation();
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const typeData = new AsYouType(language?.iso2 as CountryCode);
  const phoneExample =
    getExampleNumber(language?.iso2?.toUpperCase() as CountryCode, examples)
      ?.number || "";

  const changeLangValue = (val: any) => {
    if (!val.dialCode || !setState) return;
    setState((prevState) => {
      const nextState = { ...prevState };
      if (langName) {
        nextState[langName] = val;
      }
      nextState[name] = "+" + val.dialCode;
      return nextState;
    });
  };

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    //if (e.target.value.charAt(0) !== '+') return;
    //  if (e.target.value.length === 1 && e.target.value !== '+') {
    //setState((prevState) => ({ ...prevState, [name]: '+' + e.target.value }));
    //  return;
    //  }
    //  if (e.target.value.length <= 5) {
    //    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
    //    return;
    //  }

    const inputVal = typeData.input(e.target.value);

    if (phoneExample.length > inputVal.replace(/\s+/g, "").length) {
      if (setState) {
        setState((prevState) => {
          const nextState = { ...prevState };
          if (langName) {
            nextState[langName] = prevState[langName];
          }
          nextState[name] = inputVal;
          return nextState;
        });
      }
    } else {
      if (setState) {
        setState((prevState) => {
          const nextState = { ...prevState };
          if (langName) {
            nextState[langName] = prevState[langName];
          }
          nextState[name] = isValidPhoneNumber(
            inputVal,
            language?.iso2?.toUpperCase() as CountryCode
          )
            ? inputVal
            : prevState[name];
          return nextState;
        });
      }
    }
  };

  useEffect(() => {
    const phoneInput = phoneInputRef.current;
    if (!phoneInput) {
      return;
    }

    let dropdownContainer: Node | undefined = undefined;
    if (isMobile) {
      const containerElement = document.getElementById(
        "mobileDropdownPhoneContainer"
      );
      if (containerElement instanceof Node) {
        dropdownContainer = containerElement;
      }
    }

    const iti = intlTelInput(phoneInput, {
      initialCountry: "pl",
      localizedCountries: {
        pl: "Polska (polski)",
        gb: "Other Countries (english)",
      },
      onlyCountries: countries,
      preferredCountries: [],
      dropdownContainer: dropdownContainer,
    });

    phoneInput.addEventListener("countrychange", (e: any) => {
      const targetInput = e.target as HTMLInputElement;
      if (targetInput.value !== "+") {
        changeLangValue(iti.getSelectedCountryData());
      }
    });
  }, []);

  return (
    <InputWrapperStyles>
      <LabelStyles htmlFor={name}>{label}</LabelStyles>
      <div className={hidePhoneCode ? "hide-phone-code" : ""}>
        <PhoneInputStyledStyles
          onFocus={(e) => {
            if (selectCountryOnly) e.target.blur();
          }}
          ref={phoneInputRef}
          name={name}
          value={value}
          onChange={
            hidePhoneCode ? (e) => e.preventDefault() : (e) => changeValue(e)
          }
          type={type}
          error={error}
        />
      </div>
      <div style={{ position: "relative" }} id="mobileDropdownPhoneContainer" />
      {errorMessage && <ErrorStyles>{t(errorMessage)}</ErrorStyles>}
    </InputWrapperStyles>
  );
};

export default PhoneInput;
