import Login from "../pages/Login/Login";
import LoginKc from "../pages/Login//LoginKc";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ResetPasswordComplete from "../pages/ResetPassword/ResetPasswordComplete";
import Account from "../pages/Account/Account";
import Identification from "../pages/Identification/Identification";
import Level5 from "../pages/Level5/Level5";
import Authorization from "../pages/Authorization/Authorization";
import AuthorizationSMS from "../pages/Authorization/AuthorizationSMS";
import Register from "../pages/Register/Register";
import Seed from "../pages/Seed/Seed";
import SetPin from "../pages/SetPin/SetPin";
import { TermsAndConditions } from "../pages/TermsAndConditions/TermsAndConditions";
import NotFound500 from "../pages/NotFound500/NotFound500";
import NotFound404 from "../pages/NotFound404/NotFound404";

export interface CustomRouteProps {
  key: string;
  component: React.ComponentType<any>;
  private?: boolean;
  path?: string;
}

export const ROUTES: CustomRouteProps[] = [
  { key: "login", path: "/", component: Login },
  { key: "login-kc", path: "/login-kc", component: LoginKc },
  { key: "reset-password", path: "/password-reset", component: ResetPassword },
  {
    key: "reset-password-complete",
    path: "/password-reset/new-password",
    component: ResetPasswordComplete,
  },
  {
    key: "authorization",
    path: "/authorization",
    component: Authorization,
    private: true,
  },
  {
    key: "authorization-sms",
    path: "/authorization-sms",
    component: AuthorizationSMS,
    private: true,
  },
  { key: "register", path: "/register", component: Register },
  { key: "seed", path: "/seed", component: Seed, private: true },
  //{ key: 'terms-and-conditions', path: '/terms-and-conditions', private: false, component: TermsAndConditions },
  {
    key: "terms-and-conditions-rules",
    path: "/terms-and-conditions/rules",
    private: false,
    component: TermsAndConditions,
  },
  { key: "account", path: "/account", component: Account, private: true },
  {
    key: "identification",
    path: "/identification",
    component: Identification,
    private: true,
  },
  { key: "level-5", path: "/level-5", component: Level5, private: true },
  { key: "set-pin", path: "/set-pin", component: SetPin, private: true },
  { key: "error500", path: "/error500", component: NotFound500 },
  { key: "not-found", component: NotFound404 },
];
