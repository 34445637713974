import styled from "styled-components";

export const LayoutWrapperStyles = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0px;
  padding-bottom: 30px;
  min-height: 100vh;
  @media (max-width: 500px) {
    padding: 0 0 30px 0;
  }
`;
