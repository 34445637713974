import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { PageCenterWrapperStyles } from "../../styles/WrapperStyles";
import {
  PageDescriptionStyles,
  PageSuccessStyles,
  PageTitleStyles,
} from "../../styles/TypographyStyles";
import Input from "../../components/Input/Input";
import { useTranslation } from "react-i18next";
import { http } from "../../helpers/http";
import { ErrorStyles } from "../../components/ErrorMessage/styles";
import { validateEmail } from "../../helpers/helpers";
import { Circles } from "react-loader-spinner";

const ResetPassword: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const [errors, setErrors] = useState<{ email?: string[] }>({});
  const [form, setForm] = useState<Record<string, any>>({ email: "" });
  const { t } = useTranslation();

  const sendRequest = () => {
    if (!validateEmail(form.email)) {
      setErrors({ email: ["not found"] });
      return;
    }

    setLoading(true);
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ user: { email: form.email } }),
    };

    http(`password`, requestOptions, true)
      .then(() => {
        setErrors({});
        setLinkSent(true);
      })
      .catch((err) => {
        if (err.message) setErrors(JSON.parse(err.message));
      })
      .finally(() => setLoading(true));
  };

  return (
    <Layout>
      <PageCenterWrapperStyles maxWidth={30}>
        {linkSent ? (
          <PageSuccessStyles>{t("resetPassLinkSent")}</PageSuccessStyles>
        ) : (
          <>
            <PageTitleStyles>{t("recoverPassword")}</PageTitleStyles>
            <PageDescriptionStyles>
              {t("recoverPasswordInfo")}
            </PageDescriptionStyles>
            {isLoading ? (
              <Circles color="#818181" height={50} width={50} />
            ) : (
              <>
                <Input
                  type={"email"}
                  value={form.email}
                  setState={setForm}
                  label={t("email")}
                  name={"email"}
                />
                {errors.email && (
                  <ErrorStyles style={{ textAlign: "center" }}>
                    {errors.email[0] === "not found"
                      ? t("emailDoesntExists")
                      : t("anErrorOccurred")}
                  </ErrorStyles>
                )}
                <ButtonStyles onClick={sendRequest}>{t("next")}</ButtonStyles>
              </>
            )}
          </>
        )}
      </PageCenterWrapperStyles>
    </Layout>
  );
};
export default ResetPassword;
