import styled from "styled-components";
import { FlexStyles } from "../../styles/FlexStyles";

export const TopButtonsWrapperStyles = styled(FlexStyles)`
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 20px;
    & a {
      margin-bottom: 18px !important;
    }
  }

  @media (max-width: 880px) {
    padding-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    & a {
      margin-bottom: 18px !important;
    }
  }
`;
