import styled from "styled-components";

interface PageCenterWrapperProps {
  maxWidth?: number;
}

interface RICIBsWrapperStylesProps {
  incorrect?: boolean;
  //key?: any;
}

export const WrapperStyles = styled.div`
  width: 100%;
`;

export const PageCenterWrapperStyles = styled.div<PageCenterWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + "rem" : null)};
  margin: 0 auto;
`;

export const ModalWrapperStyles = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SingleBoxesWrapperStyles = styled.div`
  margin: 2rem 0;
`;

export const RICIBsWrapperStyles = styled(
  SingleBoxesWrapperStyles
)<RICIBsWrapperStylesProps>`
  div {
    div {
      display: flex;
    }
  }
`;

export const ButtonsWrapperStyles = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
