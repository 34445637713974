import React, { ReactNode } from "react";
import { LayoutWrapperStyles } from "./styles";
import { default as Header } from "../Header/Header";

interface LayoutProps {
  children: ReactNode;
  hideLogo?: boolean;
  withBackButton?: boolean;
  logoutButton?: boolean;
  bgc?: string;
  menuButton?: boolean;
  menuTitle?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  hideLogo,
  withBackButton,
  logoutButton,
  bgc,
  menuButton,
  menuTitle,
}) => {
  return (
    <LayoutWrapperStyles style={{ backgroundColor: bgc }}>
      <Header
        hideLogo={hideLogo}
        logoutButton={logoutButton}
        withBackButton={withBackButton}
        menuButton={menuButton}
        menuTitle={menuTitle}
      />
      {children}
    </LayoutWrapperStyles>
  );
};

export default Layout;
