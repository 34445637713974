import { RootStyles, ImageStyles } from "./styles";
import { ReactComponent as UserIcon } from "../../assets/icons/user-circle-solid.svg";

interface AvatarProps {
  url: string | undefined;
}

const Avatar: React.FC<AvatarProps> = ({ url }) => (
  <RootStyles>
    {!url ? <UserIcon /> : ""}
    <ImageStyles url={url} />
  </RootStyles>
);

export default Avatar;
