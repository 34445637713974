import { useState, useEffect, FC } from "react";
import { withTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Circles } from "react-loader-spinner";
import Input from "../../components/Input/Input";
import { parseDate } from "../../helpers/helpers";
import Alert from "../../components/Alert/Alert";
import Popup from "../../components/Popup/Popup";
import { http } from "../../helpers/http";
import StatusInputWrapper from "../../components/Input/StatusInputWrapper";
import { useTranslation } from "react-i18next";
import { DefaultThemeStyles } from "../../styles/theme/theme";
import { Grid2Styles, StyleWrapperStyles } from "./styles";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { FlexTwoItemsContainerStyles } from "../../styles/FlexStyles";
import { PageCenterWrapperStyles } from "../../styles/WrapperStyles";
import {
  MediumTitleStyles,
  PageErrorStyles,
} from "../../styles/TypographyStyles";

interface AccountProps {}

const Account: FC<AccountProps> = () => {
  const fetchKey = () => {
    http(
      `public-key`,
      {
        method: "GET",
      },
      true
    )
      .then((response) => {
        if (response.public_key == null) {
          navigate("/seed");
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(t("serverError"));
      });
  };

  useEffect(() => {
    //fetchKey();
  }, []);

  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user") || "");
  const userToken = localStorage.getItem("user-token") || "";
  const verified = localStorage.getItem("verified") || "";

  const [accDeactivateAlert, setaccDeactivateAlert] = useState(false);

  const [bussinesRedirectAlert, setBussinesRedirectAlert] = useState(false);

  const navigate = useNavigate();
  const [accAlertVisible, setAccAlertVisible] = useState(false);
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    pesel: "",
    birthDate: "",
    postCode: "",
    registeredAddress: "",
    temporaryStay: "",
    AddressCorrespondence: "",
    postCode_2: "",
    postCode_3: "",
    postCode_4: "",
    phone: '',
  });
  const [form, setForm] = useState<Record<string, any>>({
    isAccountNumberEnabled: "",
    name: "",
    secondName: "",
    lastName: "",
    secondLastName: "",
    birthDate: "",
    pesel: "",
    neighborhood: "",
    street: "",
    street_2: "",
    street_3: "",
    street_4: "",
    houseNumber: "",
    houseNumber_2: "",
    houseNumber_3: "",
    houseNumber_4: "",
    flatNumber: "",
    flatNumber_2: "",
    flatNumber_3: "",
    flatNumber_4: "",
    country: "",
    country_2: "",
    country_3: "",
    country_4: "",
    postCode: "",
    postCode_2: "",
    postCode_3: "",
    postCode_4: "",
    voivodeship: "",
    voivodeship_2: "",
    voivodeship_3: "",
    voivodeship_4: "",
    commune: "",
    commune_2: "",
    commune_3: "",
    commune_4: "",
    city: {
      zip_code: "",
      province: "",
      district: "",
      commune: "",
      city: "",
    },
    city_2: {
      zip_code_2: "",
      province_2: "",
      district_2: "",
      commune_2: "",
      city_2: "",
    },
    city_3: {
      zip_code_3: "",
      province_3: "",
      district_3: "",
      commune_3: "",
      city_3: "",
    },
    city_4: {
      zip_code_4: "",
      province_4: "",
      district_4: "",
      commune_4: "",
      city_4: "",
    },
    //city: '',
    district: "",
    district_2: "",
    district_3: "",
    district_4: "",
    phone: "",
    email: "",
    account_number: "",
    document_number: "",
    confirmation: {
      name: "",
      secondName: "",
      lastName: "",
      secondLastName: "",
      birthDate: "",
      pesel: "",
      street: "",
      street_2: "",
      street_3: "",
      street_4: "",
      houseNumber: "",
      houseNumber_2: "",
      houseNumber_3: "",
      houseNumber_4: "",
      flatNumber: "",
      flatNumber_2: "",
      flatNumber_3: "",
      flatNumber_4: "",
      postCode: "",
      postCode_2: "",
      postCode_3: "",
      postCode_4: "",
      voivodeship: "",
      voivodeship_2: "",
      voivodeship_3: "",
      voivodeship_4: "",
      commune: "",
      commune_2: "",
      commune_3: "",
      commune_4: "",
      city: "",
      city_2: "",
      city_3: "",
      city_4: "",
      district: "",
      district_2: "",
      district_3: "",
      district_4: "",
      country: "",
      country_2: "",
      country_3: "",
      country_4: "",
      phone: "",
      email: "",
      account_number: "",
      document_number: "",
    },
  });
  const [isLoading, setLoading] = useState(false);

  const fetchAccount = () => {
    setLoading(true);

    http(`users/me`, { method: "GET" }, true)
      .then((response) => {
        const user = response.data.profile;

        if (user.first_name && user.last_name) {
          if (
            localStorage.getItem("bussines_redirect_url") &&
            localStorage.getItem("bussines_auth_level")
          ) {
            if (//@ts-ignore
              localStorage.getItem("bussines_auth_level") <= response.data.authorization_level
            ) {
              setBussinesRedirectAlert(true);
            }
          }
        }

        let nameConfirmation = '';
        if (response.data.verification_institution) {

          response.data.last_verifications.map((verification: any) => {
            if(verification.name == 'bank_account' && verification.status == 'completed'){
              nameConfirmation = parseDate(new Date(verification.finished_at),t);
            }
          });

        }
        setForm((prevState) => {
          return {
            name: user.first_name || "",
            secondName: user.second_first_name || "",
            lastName: user.last_name || "",
            secondLastName: user.second_last_name || "",
            birthDate: user.birth_date || "",
            pesel: user.pesel || "",
            street: user.street || "",
            street_2: user.street_2 || "",
            street_3: user.street_3 || "",
            houseNumber: user.street_number || "",
            houseNumber_2: user.street_number_2 || "",
            houseNumber_3: user.street_number_3 || "",
            houseNumber_4: user.street_number_4 || "",
            flatNumber: user.flat_number || "",
            flatNumber_2: user.flat_number_2 || "",
            flatNumber_3: user.flat_number_3 || "",
            flatNumber_4: user.flat_number_4 || "",
            postCode: user.zip_code || "",
            postCode_2: user.zip_code_2 || "",
            postCode_3: user.zip_code_3 || "",
            postCode_4: user.zip_code_4 || "",
            voivodeship: user.province || "",
            voivodeship_2: user.province_2 || "",
            voivodeship_3: user.province_3 || "",
            voivodeship_4: user.province_4 || "",
            commune: user.commune || "",
            commune_2: user.commune_2 || "",
            commune_3: user.commune_3 || "",
            commune_4: user.commune_4 || "",
            city: user.city || "",
            district: user.district || "",
            district_2: user.district_2 || "",
            district_3: user.district_3 || "",
            district_4: user.district_4 || "",
            country: user.country || "",
            country_2: user.country_2 || "",
            country_3: user.country_3 || "",
            country_4: user.country_4 || "",
            phone: response.data.phone || "",
            email: localStorage.getItem("bussines_user_email")
              ? localStorage.getItem("bussines_user_email")
              : response.data.email || "",
            // todo
            isAccountNumberEnabled: !!user.account_number,
            account_number: user.account_number,
            document_number: user.document_number,
            confirmation: {
              name: response.data.verification_institution ? nameConfirmation : '',
              secondName: "",
              lastName: response.data.verification_institution ? nameConfirmation : '',
              secondLastName: "",
              birthDate: "",
              pesel: "",
              street: "",
              street_2: "",
              street_3: "",
              street_4: "",
              houseNumber: "",
              houseNumber_2: "",
              houseNumber_3: "",
              houseNumber_4: "",
              flatNumber: "",
              flatNumber_2: "",
              flatNumber_3: "",
              flatNumber_4: "",
              postCode: "",
              postCode_2: "",
              postCode_3: "",
              postCode_4: "",
              voivodeship: "",
              voivodeship_2: "",
              voivodeship_3: "",
              voivodeship_4: "",
              commune: "",
              commune_2: "",
              commune_3: "",
              commune_4: "",
              city: "",
              city_2: "",
              city_3: "",
              city_4: "",
              district: "",
              country: "",
              country_2: "",
              country_3: "",
              country_4: "",

              phone: response.data.phone_confirmed_at
                ? parseDate(new Date(response.data.phone_confirmed_at), t)
                : "",
              email: response.data.confirmed_at
                ? parseDate(new Date(response.data.confirmed_at), t)
                : "",
              account_number: "",
              document_number: "",
            },
          };
        });

      })
      .catch((err) => {
        console.error("error:", err);
      })
      .finally(() => setLoading(false));
  };

  const updateAccount = () => {

    setValidationErrors((validationErrors) => ({
      ...validationErrors,
      pesel: '',
      birthDate: '',
    }));

    setLoading(true);

    if(form.pesel && form.pesel.length != 11){
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        pesel: 'Nieprawidłowy format pesel',
      }));
      setLoading(false)
      return;
    }

    if(form.birthDate && new Date(form.birthDate) > new Date()){
      setValidationErrors((validationErrors) => ({
        ...validationErrors,
        birthDate: 'Niepoprawna data',
      }));
      setLoading(false)
      return;
    }

    const postData = {
      profile: {
        first_name: form.name,
        second_first_name: form.secondName,
        last_name: form.lastName,
        second_last_name: form.secondLastName,
        neighborhood: form.neighborhood,
        street: form.street,
        street_2: form.street_2,
        street_3: form.street_3,
        street_4: form.street_4,
        street_number: form.houseNumber,
        street_number_2: form.houseNumber_2,
        street_number_3: form.houseNumber_3,
        street_number_4: form.houseNumber_4,
        flat_number: form.flatNumber,
        flat_number_2: form.flatNumber_2,
        flat_number_3: form.flatNumber_3,
        flat_number_4: form.flatNumber_4,
        zip_code: form.postCode,
        zip_code_2: form.postCode_2,
        zip_code_3: form.postCode_3,
        zip_code_4: form.postCode_4,
        province: form.voivodeship,
        province_2: form.voivodeship_2,
        province_3: form.voivodeship_3,
        province_4: form.voivodeship_4,
        commune: form.commune,
        commune_2: form.commune_2,
        commune_3: form.commune_3,
        commune_4: form.commune_4,
        city: form.city,
        city_2: form.city_2,
        city_3: form.city_3,
        city_4: form.city_4,
        district: form.district,
        district_2: form.district_2,
        district_3: form.district_3,
        district_4: form.district_4,
        country: form.country,
        country_2: form.country_2,
        country_3: form.country_3,
        country_4: form.country_4,
        pesel: form.pesel,
        birth_date: form.birthDate,
        account_number: form.account_number,
        document_number: form.document_number,
      },
    };

    http(
      `profile`,
      {
        method: "PATCH",
        body: JSON.stringify(postData),
      },
      true
    )
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("verified", response.data.verification_status);

        const user = response.data.profile;

        if (form?.phone?.length && !form.confirmation.phone) {
          localStorage.setItem("phone-chainging", "true");
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...response.data,
              phone: form.phone.replace(/\s+/g, "").replace(`+`, ""),
            })
          );

          if (!(form?.email?.length && !form.confirmation.email)) {
            const requestOptions = {
              method: "POST",
              body: JSON.stringify({
                user: {
                  phone: form.phone.replace(/\s+/g, "").replace(`+`, ""),
                },
              }),
            };
            http(`phone-confirmation`, requestOptions, true).then(
              (response) => {
                navigate("/authorization-sms");
              }
            ).catch((error) => {

              setValidationErrors((validationErrors) => ({
                ...validationErrors, // zachowaj wszystkie istniejące pola
                phone: error,   // ustaw nową wartość dla pola "phone"
              }));
            })
            ;
          }
        }

        if (form?.email?.length && !form.confirmation.email) {
          const requestOptions = {
            method: "POST",
            body: JSON.stringify({ user: { email: form.email } }),
          };

          http(`email-confirmation`, requestOptions, true).finally(() => {
            if (form?.phone?.length && !form.confirmation.phone) {
              localStorage.setItem(
                "user",
                JSON.stringify({
                  ...response.data,
                  email: form.email,
                  phone: form.phone.replace(/\s+/g, "").replace(`+`, ""),
                })
              );
            } else {
              localStorage.setItem(
                "user",
                JSON.stringify({ ...response.data, email: form.email })
              );
            }
            localStorage.setItem("email-chainging", "true");
            navigate("/authorization");
          });
        }
      })
      .catch((error) => {
        console.error("error:", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // !user && history.push('/');
    // confirmationStatus === 'null' && user && history.push('/authorization');
    fetchAccount();

    return () => setLoading(false);
  }, []);

  // clear account_number on toggle to false checkbox
  useEffect(() => {
    if (form.isAccountNumberEnabled) return;
    setForm((prevState) => {
      return { ...prevState, account_number: "" };
    });
  }, [form.isAccountNumberEnabled]);

  const [temporaryStay, setTemporaryStay] = useState(false);
  const [addressCorrespond, setAddressCorrespond] = useState(false);
  const [addressData, setAddressData] = useState(false);

  //buttons

  const [isEditMode, setIsEditMode] = useState(false);
  const [editable, setEditable] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(true);
    setEditable(true);
  };

  const handleSaveClick = () => {
    const onSave =
      verified === "completed" ? () => setAccAlertVisible(true) : updateAccount;
    onSave();
    setIsEditMode(false);
    setEditable(false);
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setEditable(false);
  };

  //teryt

  interface MyData {
    something: string;
  }

  const [voivodeships, setVoivodeships] = useState<string[]>([]);
  const [districts, setDistricts] = useState<string[]>([]);
  const [communes, setCommunes] = useState<string[]>([]);
  const [terytCode, setTerytCode] = useState<string[]>([]);
  const [cities, setCity] = useState<string[]>([]);
  const [simcSymbol, setSimcSymbol] = useState<string[]>([]);
  const [streets, setStreets] = useState<string[]>([]);

  useEffect(() => {
    if (form.postCode) {
      if (form.postCode.length === 6) {
        http(
          `addresses/find-districts?zip_code=${form.postCode}`,
          { method: "GET" },
          true
        )
          .then((data: MyData) => {
            if (Object.values(data)[0].length > 0) {
              const voivodeships = Object.values(data).map(
                (province) => province[0][0]
              );
              setVoivodeships(voivodeships);
            } else {
              setVoivodeships([]);
            }
          })
          .catch((error) => console.error(error));
      }
    }
  }, [form.postCode]);

  useEffect(() => {
    if (form.postCode && form.voivodeship) {
      const province = encodeURIComponent(form.voivodeship);

      http(
        `addresses/find-districts?zip_code=${form.postCode}&province=${province}`,
        { method: "GET" },
        true
      )
        .then((data: MyData) => {
          if (Object.values(data)[0].length > 0) {
            const districts = Object.values(data).map(
              (district) => district[0][1]
            );
            setDistricts(districts);
          } else {
            setDistricts([]);
            setCommunes([]);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [form.postCode, form.voivodeship]);

  useEffect(() => {
    if (form.postCode && form.voivodeship && form.district) {
      const province = encodeURIComponent(form.voivodeship);
      const district = encodeURIComponent(form.district);

      http(
        `addresses/find-communes?province=${province}&district=${district}`,
        { method: "GET" },
        true
      )
        .then((result) => {
          const communes = result.data.map(
            (commune: any) =>
              `${commune.name} (${commune.additional_name}) (${commune.teryt_code})`
          );
          setCommunes(communes);

          const communeIndex = result.data.findIndex((commune: any) => {
            return (
              `${commune.name} (${commune.additional_name}) (${commune.teryt_code})` ===
              form.commune
            );
          });

          if (communeIndex !== -1) {
            const terytCode = result.data[communeIndex].teryt_code;
            setTerytCode(terytCode);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [form.postCode, form.voivodeship, form.district, form.commune]);

  useEffect(() => {
    if (
      form.postCode &&
      form.voivodeship &&
      form.district &&
      terytCode.length > 3
    ) {
      http(
        `addresses/find-localities?teryt_code=${terytCode}`,
        { method: "GET" },
        true
      )
        .then((result) => {
          const cities = result.data.map(
            (city: any) => `${city.locality} (${city.primary_simc_symbol})`
          );
          setCity(cities);

          const cityIndex = result.data.findIndex((city: any) => {
            return (
              `${city.locality} (${city.primary_simc_symbol})` === form.city
            );
          });

          if (cityIndex !== -1) {
            const simcSymbol = result.data[cityIndex].primary_simc_symbol;
            setSimcSymbol(simcSymbol);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [form.postCode, form.voivodeship, form.district, form.city, terytCode]);

  useEffect(() => {
    if (form.postCode && form.voivodeship && form.district && form.city) {
      http(
        `addresses/find-streets?simc_symbol=${simcSymbol}&per_page=500`,
        { method: "GET" },
        true
      )
        .then((result) => {
          const streets = result.data.map(
            (street: any) =>
              `${street.street_type} ${street.name_second} ${street.name}`
          );
          setStreets(streets);
        })
        .catch((error) => console.log("error", error));
    }
  }, [
    form.postCode,
    form.voivodeship,
    form.district,
    form.commune,
    form.city,
    simcSymbol,
  ]);

  return (
    <Layout
      hideLogo
      menuButton
      bgc={DefaultThemeStyles.colors.backgroundSecondary}
      menuTitle={t("personalAccount")}
    >
      {error ? (
        <PageErrorStyles>{error}</PageErrorStyles>
      ) : (
        <>
          <PageCenterWrapperStyles maxWidth={900} style={{ paddingBottom: 40 }}>
            <StyleWrapperStyles>
              <FlexTwoItemsContainerStyles width="90%" breakSmall="430px">
                <div>
                  <MediumTitleStyles>{t("primaryData")}</MediumTitleStyles>
                  <StatusInputWrapper success={form.confirmation.name}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.name}
                      type={"text"}
                      label={t("name")}
                      name={"name"}
                      success={form.confirmation.name}
                      errorMessage={
                        (localStorage.getItem("bussines_redirect_url") && !form.name) ? 'Uzupełnij pole aby wrócić do iVotingBussines' : ''
                      }
                    />
                  </StatusInputWrapper>

                  <StatusInputWrapper success={form.confirmation.secondName}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.secondName}
                      type={"text"}
                      label={t("secondName")}
                      name={"secondName"}
                      success={form.confirmation.secondName}
                    />
                  </StatusInputWrapper>

                  <StatusInputWrapper success={form.confirmation.lastName}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.lastName}
                      type={"text"}
                      label={t("lastName")}
                      name={"lastName"}
                      success={form.confirmation.name}
                      errorMessage={
                        (localStorage.getItem("bussines_redirect_url") && !form.lastName) ? 'Uzupełnij pole aby wrócić do iVotingBusiness' : ''
                      }
                    />
                  </StatusInputWrapper>

                  <StatusInputWrapper
                    success={form.confirmation.secondLastName}
                  >
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.secondLastName}
                      type={"text"}
                      label={t("Drugie Nazwisko")}
                      name={"secondLastName"}
                      success={form.confirmation.secondLastName}
                    />
                  </StatusInputWrapper>

                  <StatusInputWrapper success={form.confirmation.email}>
                    {!localStorage.getItem("bussines_user_email") ? (
                      <Input
                        disabled={!editable}
                        setState={setForm}
                        value={form.email}
                        type={"text"}
                        label={t("email")}
                        name={"email"}
                        placeholder={'format: adres@domena.pl'}
                        success={form.confirmation.email}
                        errorMessage={
                          (localStorage.getItem("bussines_redirect_url") && user.authorization_level < 1) ? 'Zweryfikuj email aby wrócić do iVotingBusiness' : ''
                        }
                      />
                    ) : (
                      <Input
                        disabled={true}
                        setState={setForm}
                        value={form.email}
                        type={"text"}
                        label={t("email")}
                        name={"email"}
                        placeholder={localStorage.getItem(
                          "bussines_user_email"
                        )}
                        success={form.confirmation.email}
                        errorMessage={
                          (localStorage.getItem("bussines_redirect_url") && user.authorization_level < 1) ? 'Zweryfikuj email aby wrócić do iVotingBusiness' : ''
                        }
                      />
                    )}
                  </StatusInputWrapper>
                  <StatusInputWrapper success={form.confirmation.phone}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.phone}
                      type={"text"}
                      label={t("phone")}
                      name={"phone"}
                      error={validationErrors.phone ? true : false}
                      errorMessage={
                        validationErrors.phone && validationErrors.phone
                      }
                      placeholder={'format: +48 233 932 542'}
                      success={form.confirmation.phone}
                    />
                  </StatusInputWrapper>
                  <StatusInputWrapper success={form.confirmation.birthDate}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.birthDate}
                      type={"date"}
                      label={t("birthDate")}
                      name={"birthDate"}
                      error={validationErrors.birthDate ? true : false}
                      errorMessage={
                        validationErrors.birthDate && validationErrors.birthDate
                      }
                      success={form.confirmation.birthDate}
                    />
                  </StatusInputWrapper>
                  <StatusInputWrapper success={form.confirmation.pesel}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.pesel}
                      type={'text'}
                      placeholder={"format: 91070227866"}
                      label={t("Pesel")}
                      name={"pesel"}
                      error={validationErrors.pesel ? true : false}
                      errorMessage={
                        validationErrors.pesel && validationErrors.pesel
                      }
                      success={form.confirmation.pesel}
                    />
                  </StatusInputWrapper>
                </div>
                <div>
                  <MediumTitleStyles>{t("permanentAddress")}</MediumTitleStyles>
                  <StatusInputWrapper success={form.confirmation.country}>
                    <Input
                      disabled={!editable}
                      setState={setForm}
                      value={form.country}
                      type={"text"}
                      label={t("country")}
                      name={"country"}
                      success={form.confirmation.country}
                    />
                  </StatusInputWrapper>
                  {form.country === "Polska" || form.country === "polska" ? (
                    <>
                      <StatusInputWrapper success={form.confirmation.postCode}>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.postCode}
                          type={"text"}
                          label={t("postCode")}
                          name={"postCode"}
                          error={validationErrors.postCode ? true : false}
                          errorMessage={
                            validationErrors.postCode &&
                            validationErrors.postCode
                          }
                          success={form.confirmation.postCode}
                        />
                      </StatusInputWrapper>

                      <StatusInputWrapper
                        success={form.confirmation.voivodeship}
                      >
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.voivodeship}
                          type={"select"}
                          label={t("voivodeship")}
                          name={"voivodeship"}
                          success={form.confirmation.voivodeship}
                          options={voivodeships}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper success={form.confirmation.district}>
                        <Input
                          disabled={!editable || !form.voivodeship}
                          setState={setForm}
                          value={form.district}
                          type={"select"}
                          label={t("district")}
                          name={"district"}
                          success={form.confirmation.district}
                          options={districts}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper success={form.confirmation.commune}>
                        <Input
                          disabled={!editable || !form.voivodeship}
                          setState={setForm}
                          value={form.commune}
                          type={"select"}
                          label={t("commune")}
                          name={"commune"}
                          success={form.confirmation.commune}
                          options={communes}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper success={form.confirmation.city}>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.city}
                          type={"select"}
                          label={t("city")}
                          name={"city"}
                          success={form.confirmation.city}
                          options={cities}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper success={form.confirmation.street}>
                        <Input
                          disabled={!editable || !form.district}
                          setState={setForm}
                          value={form.street}
                          type={"select"}
                          label={t("street")}
                          name={"street"}
                          success={form.confirmation.street}
                          options={streets}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.houseNumber}
                          type={"text"}
                          label={t("houseNumber")}
                          name={"houseNumber"}
                          success={form.confirmation.houseNumber}
                        />
                        <Grid2Styles style={{ width: "10px" }} />
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.flatNumber}
                          type={"text"}
                          label={t("flatNumber")}
                          name={"flatNumber"}
                          success={form.confirmation.flatNumber}
                        />
                      </StatusInputWrapper>
                    </>
                  ) : (
                    <>
                      <StatusInputWrapper success={form.confirmation.postCode}>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.postCode}
                          type={"text"}
                          label={t("postCode")}
                          name={"postCode"}
                          error={validationErrors.postCode ? true : false}
                          errorMessage={
                            validationErrors.postCode &&
                            validationErrors.postCode
                          }
                          success={form.confirmation.postCode}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          type={"text"}
                          label={t("city")}
                          name={"city"}
                          success={form.confirmation.city}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.street}
                          type={"text"}
                          label={t("street")}
                          name={"street"}
                          success={form.confirmation.street}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.houseNumber}
                          type={"text"}
                          label={t("houseNumber")}
                          name={"houseNumber"}
                          success={form.confirmation.houseNumber}
                        />
                        <Grid2Styles style={{ width: "10px" }} />
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.flatNumber}
                          type={"text"}
                          label={t("flatNumber")}
                          name={"flatNumber"}
                          success={form.confirmation.flatNumber}
                        />
                      </StatusInputWrapper>
                    </>
                  )}
                  <StatusInputWrapper>
                    <Input
                      id="registeredAddress"
                      setState={() => setTemporaryStay(!temporaryStay)}
                      label={t("checkboxLabel")}
                      smoothLabel={true}
                      type={"checkbox"}
                      name={"registeredAddress"}
                      error={!!validationErrors.registeredAddress}
                      errorMessage={
                        validationErrors.registeredAddress &&
                        validationErrors.registeredAddress
                      }
                    />
                  </StatusInputWrapper>
                  {temporaryStay === true ? (
                    <>
                      <MediumTitleStyles>
                        {t("temporaryStay")}
                      </MediumTitleStyles>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.country_2}
                          type={"text"}
                          label={t("country")}
                          name={"country_2"}
                          success={form.confirmation.country_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper
                        success={form.confirmation.postCode_2}
                      >
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.postCode_2}
                          type={"text"}
                          label={t("postCode")}
                          name={"postCode_2"}
                          error={validationErrors.postCode_2 ? true : false}
                          errorMessage={
                            validationErrors.postCode_2 &&
                            validationErrors.postCode_2
                          }
                          success={form.confirmation.postCode_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.voivodeship_2}
                          type={"text"}
                          label={t("voivodeship")}
                          name={"voivodeship_2"}
                          success={form.confirmation.voivodeship_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.commune_2}
                          type={"text"}
                          label={t("commune")}
                          name={"commune_2"}
                          success={form.confirmation.commune_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.city_2}
                          type={"text"}
                          label={t("city")}
                          name={"city_2"}
                          success={form.confirmation.city_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.district_2}
                          type={"text"}
                          label={t("district")}
                          name={"district_2"}
                          success={form.confirmation.district_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.street_2}
                          type={"text"}
                          label={t("street")}
                          name={"street_2"}
                          success={form.confirmation.street_2}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.houseNumber_2}
                          type={"text"}
                          label={t("houseNumber")}
                          name={"houseNumber_2"}
                          success={form.confirmation.houseNumber_2}
                        />
                        <Grid2Styles style={{ width: "10px" }} />
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.flatNumber_2}
                          type={"text"}
                          label={t("flatNumber")}
                          name={"flatNumber_2"}
                          success={form.confirmation.flatNumber_2}
                        />
                      </StatusInputWrapper>
                    </>
                  ) : (
                    <></>
                  )}
                  <StatusInputWrapper>
                    <Input
                      id="temporaryStay"
                      setState={() => setAddressData(!addressData)}
                      label={t("checkboxLabel2")}
                      smoothLabel={true}
                      type={"checkbox"}
                      name={"temporaryStay"}
                      error={!!validationErrors.temporaryStay}
                      errorMessage={
                        validationErrors.temporaryStay &&
                        validationErrors.temporaryStay
                      }
                    />
                  </StatusInputWrapper>
                  {addressData === true ? (
                    <>
                      <MediumTitleStyles>{t("addressData")}</MediumTitleStyles>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.country_3}
                          type={"text"}
                          label={t("country")}
                          name={"country_3"}
                          success={form.confirmation.country_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper
                        success={form.confirmation.postCode_3}
                      >
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.postCode_3}
                          type={"text"}
                          label={t("postCode")}
                          name={"postCode_3"}
                          error={validationErrors.postCode_3 ? true : false}
                          errorMessage={
                            validationErrors.postCode_3 &&
                            validationErrors.postCode_3
                          }
                          success={form.confirmation.postCode_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.voivodeship_3}
                          type={"text"}
                          label={t("voivodeship")}
                          name={"voivodeship_3"}
                          success={form.confirmation.voivodeship_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.commune_3}
                          type={"text"}
                          label={t("commune")}
                          name={"commune_3"}
                          success={form.confirmation.commune_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.city_3}
                          type={"text"}
                          label={t("city")}
                          name={"city_3"}
                          success={form.confirmation.city_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.district_3}
                          type={"text"}
                          label={t("district")}
                          name={"district_@"}
                          success={form.confirmation.district_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.street_3}
                          type={"text"}
                          label={t("street")}
                          name={"street_3"}
                          success={form.confirmation.street_3}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.houseNumber_3}
                          type={"text"}
                          label={t("houseNumber")}
                          name={"houseNumber_3"}
                          success={form.confirmation.houseNumber_3}
                        />
                        <Grid2Styles style={{ width: "10px" }} />
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.flatNumber_3}
                          type={"text"}
                          label={t("flatNumber")}
                          name={"flatNumber_3"}
                          success={form.confirmation.flatNumber_3}
                        />
                      </StatusInputWrapper>
                    </>
                  ) : (
                    <></>
                  )}
                  <StatusInputWrapper>
                    <Input
                      id="AddressCorrespondence"
                      setState={() => setAddressCorrespond(!addressCorrespond)}
                      label={t("checkboxLabel3")}
                      smoothLabel={true}
                      value={form.AddressCorrespondence}
                      type={"checkbox"}
                      name={"AddressCorrespondence"}
                      error={!!validationErrors.AddressCorrespondence}
                      errorMessage={
                        validationErrors.AddressCorrespondence &&
                        validationErrors.AddressCorrespondence
                      }
                    />
                  </StatusInputWrapper>
                  {addressCorrespond === true ? (
                    <>
                      <MediumTitleStyles>
                        {t("addressCorrespondence")}
                      </MediumTitleStyles>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.country_4}
                          type={"text"}
                          label={t("country")}
                          name={"country_4"}
                          success={form.confirmation.country_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper
                        success={form.confirmation.postCode_4}
                      >
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.postCode_4}
                          type={"text"}
                          label={t("postCode")}
                          name={"postCode_4"}
                          error={validationErrors.postCode_4 ? true : false}
                          errorMessage={
                            validationErrors.postCode_4 &&
                            validationErrors.postCode_4
                          }
                          success={form.confirmation.postCode_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.voivodeship_4}
                          type={"text"}
                          label={t("voivodeship")}
                          name={"voivodeship_4"}
                          success={form.confirmation.voivodeship_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.commune_4}
                          type={"text"}
                          label={t("commune")}
                          name={"commune_4"}
                          success={form.confirmation.commune_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.city_4}
                          type={"text"}
                          label={t("city")}
                          name={"city_4"}
                          success={form.confirmation.city_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.district_4}
                          type={"text"}
                          label={t("district")}
                          name={"district_4"}
                          success={form.confirmation.district_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.street_4}
                          type={"text"}
                          label={t("street")}
                          name={"street_4"}
                          success={form.confirmation.street_4}
                        />
                      </StatusInputWrapper>
                      <StatusInputWrapper>
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.houseNumber_4}
                          type={"text"}
                          label={t("houseNumber")}
                          name={"houseNumber_4"}
                          success={form.confirmation.houseNumber_4}
                        />
                        <Grid2Styles style={{ width: "10px" }} />
                        <Input
                          disabled={!editable}
                          setState={setForm}
                          value={form.flatNumber_4}
                          type={"text"}
                          label={t("flatNumber")}
                          name={"flatNumber_4"}
                          success={form.confirmation.flatNumber_4}
                        />
                      </StatusInputWrapper>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </FlexTwoItemsContainerStyles>
              {isLoading ? (
                <Circles color="#818181" height={50} width={50} />
              ) : (
                <center>
                  {isEditMode ? (
                    <>
                      <ButtonStyles
                        onClick={handleSaveClick}
                      >
                        {t("save")}
                      </ButtonStyles>               <br /> <br />
                      <ButtonStyles onClick={handleCancelClick}>
                        {t("cancel")}
                      </ButtonStyles>
                      <br />
                      <br />
                    </>
                  ) : (
                    <center>
                      <ButtonStyles onClick={handleEditClick}>
                        {t("edit")}
                      </ButtonStyles>
                      <br />
                      <br />
                    </center>
                  )}
                </center>
              )}
              {accAlertVisible && (
                <Alert
                  yesno
                  onClickYes={updateAccount}
                  onClickNo={() => setAccAlertVisible(false)}
                >
                  {t("accountAlert2")}
                </Alert>
              )}

              {accDeactivateAlert && (
                <div className="overlay_acc">
                  <Popup
                    title={t("wantToDeactivateYourAccount")}
                    description={t("deactivateDescription")}
                    form={
                      <>
                        <ButtonStyles
                          onClick={() =>
                            setaccDeactivateAlert(!accDeactivateAlert)
                          }
                        >
                          {" "}
                          {t("cancel")}
                        </ButtonStyles>{" "}
                        <ButtonStyles style={{ marginTop: "15px" }}>
                          {" "}
                          {t("next")}
                        </ButtonStyles>
                      </>
                    }
                  />
                </div>
              )}
              {bussinesRedirectAlert && (
                <div className="overlay_acc">
                  <Popup
                    title={t("bussinesDescription")}
                    description={t("bussinesDescription2")}
                    form={
                      <>
                        <ButtonStyles
                          onClick={() =>
                            setBussinesRedirectAlert(!bussinesRedirectAlert)

                          }
                        >
                          {" "}
                          {t("cancel")}
                        </ButtonStyles>{" "}
                        <ButtonStyles
                          style={{ marginTop: "15px" }}
                          onClick={() => {
                            const redirectUrl = localStorage.getItem(
                              "bussines_redirect_url"
                            );
                            if (redirectUrl) {

                              localStorage.removeItem("bussines_redirect_url");
                              window.location.replace(redirectUrl);

                            } else {
                              console.log("Redirect URL is not available.");
                            }
                          }}
                        >
                          {" "}
                          {t("next")}
                        </ButtonStyles>
                      </>
                    }
                  />
                </div>
              )}
              <center>
                <ButtonStyles
                  onClick={() => setaccDeactivateAlert(!accDeactivateAlert)}
                >
                  {t("deactivateAccount")}
                </ButtonStyles>
              </center>
            </StyleWrapperStyles>
          </PageCenterWrapperStyles>
        </>
      )}
    </Layout>
  );
};

export default withTheme(Account);
