export const DefaultThemeStyles = {
  colors: {
    backgroundPrimary: "white",
    backgroundSecondary: "#F0F0F0",
    circleColor: "#2c2137",
    backgroundDisabled: "#EEEEEE",
    link: "#BB173D",
    buttonPrimaryBgc: "#BB173D",
    buttonSecondaryBgc: "#e80056",
    buttonPrimaryFont: "#ffffff",
    buttonBlue: "#3f6cec",
    textPrimary: "black",
    white: "#ffffff",
    textSecondary: "#818181",
    textSuccess: "#8dc38e",
    textThird: "#972b37",
    inputBorder: "grey",
    error: "#ff5d5d",
  },
};

export type Theme = typeof DefaultThemeStyles;
