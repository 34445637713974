import styled from "styled-components";

export const WrapperStyles = styled.div`
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 35rem;
  width: 100%;
  min-height: 30rem;
  border-radius: 16px;
  text-align: center;
  background: white;
  padding-top: 4rem;
`;
