import styled from "styled-components";

interface ErrorStylesProps {
  isRed?: boolean;
}

export const ErrorStyles = styled.span<ErrorStylesProps>`
  display: inline-block;
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: ${({ theme, isRed }) =>
    isRed ? theme.colors.error : theme.colors.error};
`;
