import {
  SubtitleStyles,
  WrapperStyles,
  ContentWrapperStyles,
  ImageStyles,
  TitleStyles,
  DescriptionStyles,
  ButtonStyles,
} from "./styles";
import errorImg from "../../assets/icons/images/error404.png";
import { useTranslation } from "react-i18next";

const NotFound404 = () => {
  const { t } = useTranslation();
  return (
    <WrapperStyles>
      <ContentWrapperStyles>
        <ImageStyles src={errorImg} alt="404 Error" />
        <SubtitleStyles>404</SubtitleStyles>
        <TitleStyles>{t("notFound")}</TitleStyles>
        <DescriptionStyles>{t("descriptionError404")}</DescriptionStyles>
        <ButtonStyles style={{ fontSize: "20px" }} href="/">
          {t("buttonError")}
        </ButtonStyles>
      </ContentWrapperStyles>
    </WrapperStyles>
  );
};

export default NotFound404;
