import { createGlobalStyle } from "styled-components";

interface ThemeProps {
  theme: {
    colors: {
      backgroundPrimary: string;
      textPrimary: string;
      white: string;
      link: string;
    };
  };
}

export const GlobalStyles = createGlobalStyle<ThemeProps>`
  *, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  body {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    font-family: 'Avenir', sans-serif;
  }

  html {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 10px;
  }

  *:focus {
    outline: none;
  }

  ul, ol {
    list-style: none;
  }

  button {
    border: none;
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
  }

  input, textarea {
    font-family: inherit;

    &::placeholder {
      font-family: inherit;
    }
  }

  // PHONE INPUT
  .iti {
    width: 100%;

    .iti__country.iti__standard {
      font-size: 14px;
    }

    &.iti--allow-dropdown {
      margin: 1rem 0 !important;
    }

    .iti__selected-flag {
      padding-left: 16px;
    }

    .iti__arrow {
      border-top-color: white;
    }

    .iti__arrow--up {
      border-bottom-color: white;
    }

    .iti__country-name {
      color: black;
    }

    .iti__country-list {
      margin-left: 24px;
      min-width: 270px;
    }
  }

  .iti-mobile {
    .iti.iti--container {
      left: 0;
      position: absolute;
      top: -10px;
      max-height: unset;
      min-height: 300px;
    }
  }

  .hide-phone-code .iti__dial-code {
    display: none;
  }

  // React select
  .react-select-container {
    width: 100%;
    margin-bottom: 1.8rem;

    .react-select__control {
      transition: unset;
      margin: 1rem 0;
      background-color: transparent;
      box-shadow: unset;
      font-size: 1.6rem;
      padding: 0 16px;
      height: 52px;
      border: 3px solid ${({ theme }) => theme.colors.white};
      border-radius: 25px;
      width: 100%;

      :focus:not(:hover) {
        display: none;
        outline: none;
      }

      &.react-select__control--menu-is-open {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
      }

      .react-select__input-container, .react-select__input {
        color: ${({ theme }) => theme.colors.white};
      }
      .react-select__indicator-separator {
        width: 2px;
        background-color: ${({ theme }) => theme.colors.white};
      }

      .react-select__indicator {
        svg {
          fill: ${({ theme }) => theme.colors.white}
        }
      }

      .react-select__single-value {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    .react-select__control:hover {
      border-color: unset;
      box-shadow: unset;
    }

    .react-select__menu {
      overflow: hidden;
      background-color: ${({ theme }) => theme.colors.backgroundPrimary};
      border-radius: 25px;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border: 3px solid white;
      margin-top: -3px;

      .react-select__option {
        font-size: 1.6rem;
        padding: 8px 24px;

        &.react-select__option--is-focused {
          background-color: unset;
        }
        &.react-select__option--is-selected {
          background-color: ${({ theme }) => theme.colors.link};
        }
        :hover {
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.backgroundPrimary};
        }
      }
    }
  }
`;
