import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import RICIBs from "react-individual-character-input-boxes";
import Layout from "../../components/Layout/Layout";
import {
  PageCenterWrapperStyles,
  RICIBsWrapperStyles,
  WrapperStyles,
} from "../../styles/WrapperStyles";
import {
  PageDescriptionStyles,
  PageTitleStyles,
} from "../../styles/TypographyStyles";
import { ButtonStyles } from "../../styles/ButtonStyles";
import { ErrorStyles } from "../../components/ErrorMessage/styles";
import { useTranslation } from "react-i18next";
import { BoxStyles } from "../../styles/BoxStyles";
import { http } from "../../helpers/http";
import { FlexStyles } from "../../styles/FlexStyles";
import { ReactComponent as Envelope } from "../../assets/icons/envelope-open-text-solid.svg";
import Popup from "../../components/Popup/Popup";

const Authorization: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") || "");
  const phoneCode = localStorage.getItem("phoneCode") || "";
  const phone = user?.phone ?? false;
  const email = user?.email ?? "";
  const confirmationStatus = localStorage.getItem("confirmed");
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [prevConfirmed, setPrevConfirmed] = useState(false);
  const [serverError, setServerError] = useState("");
  const [timeStatus, setTimeStatus] = useState(60);
  const [inter, setInter] = useState<NodeJS.Timer | null>(null);
  const [timeAlertVisible, setTimeAlertVisible] = useState(false);
  const [showPOP, setShowPOP] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // (!email || !phone || !phoneCode) && history.push('/');
    // confirmationStatus && confirmationStatus !== 'null' && history.push('/identification');
  }, [email, phone, phoneCode, navigate, confirmationStatus]);

  useEffect(() => {
     if (email) {
       const intervalId = setInterval(() => {
         setTimeStatus((prevStatus) => prevStatus - 1);
       }, 1000);

       setInter(intervalId);
     }

     return () => {
       if (inter) {
         clearInterval(inter);
       }
     };
   }, [email, inter]);

   useEffect(() => {
     if (timeStatus <= 0) {
       if (inter) {
         clearInterval(inter);
         setTimeAlertVisible(true);
       }
     }
   }, [timeStatus]);

   const parseEmail = (email: string) => {
     let numberToHide = email.substring(1, email.lastIndexOf("@")).length - 1;
     let lastLetters = email.substring(email.lastIndexOf("@"), email.length);
     let stars = "";
     for (var i = 1; i <= numberToHide; i++) {
       stars += "*";
     }
     return (
       email.charAt(0) + stars + email.charAt(numberToHide + 1) + lastLetters
     );
   };

   const handleString = (output: string) => {
     setCode(output);
   };

  const handleClick = () => {
    setLoading(true);
    setIncorrect(false);
    setPrevConfirmed(false);

    if (code.length !== 6) {
      setLoading(false);
      setIncorrect(true);
      return;
    }

    http(
      `email-confirmation?confirmation_token=${code}`,
      {
        method: "GET",
      },
      true
    )
      .then((response) => {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, email: response.data.email })
        );

        if (
          localStorage.getItem("email-chainging") &&
          !localStorage.getItem("phone-chainging")
        ) {
          localStorage.removeItem("email-chainging");
          setShowPOP(true);
          setTimeout(function () {}, 5000);
          navigate("/");
        } else {
          setShowPOP(true);
          setTimeout(function () {
            navigate("/identification");
          }, 5000);
        }
      })
      .catch((err) => {
        const errorData = JSON.parse(err.message);
        if (
          errorData.confirmation_token &&
          errorData.confirmation_token[0] === "is invalid"
        ) {
          setIncorrect(true);
          setCode("");
        }
        if (
          errorData.email &&
          errorData.email[0] === "was already confirmed, please try signing in"
        )
          setPrevConfirmed(true);
        if (errorData.status && errorData.status === 500)
          setServerError("serverErrorOccurred");
      })
      .finally(() => setLoading(false));
  };

  const handleResend = () => {
    setLoading(true);
    setIncorrect(false);
    setPrevConfirmed(false);
    setTimeStatus(60);

    const requestOptions = {
      method: "PUT",
      body: JSON.stringify({}),
    };

    http(`email-confirmation`, requestOptions, true)
      .catch((err) => {
        const errorData = JSON.parse(err.message);
        if (errorData.error && errorData.error === "Email not found")
          setIncorrect(true);
        if (
          errorData.error &&
          errorData.error ===
            "Email was already confirmed, please try signing in"
        )
          setPrevConfirmed(true);
        if (errorData.status && errorData.status === 500)
          setServerError("serverErrorOccurred");
        setCode("");
      })
      .finally(() => setLoading(false));
  };

  return showPOP == true ? (
    <WrapperStyles
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        maxWidth: "30rem",
        margin: "0 auto",
        fontSize: "14px",
      }}
    >
      <FlexStyles
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "rgb(125,125,125)",
          height: "100%",
          width: "100%",
          position: "fixed",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Popup
          icon={<Envelope style={{ marginTop: "10px" }} />}
          title={t("verificationCode")}
          description={t("ConfirmationVerificationCode")}
          form={<ButtonStyles style={{ marginTop: "10px" }}>Ok</ButtonStyles>}
        />
      </FlexStyles>
    </WrapperStyles>
  ) : (
    <>
      <Layout>
        <PageCenterWrapperStyles
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            maxWidth: "30rem",
            margin: "0 auto",
            fontSize: "14px",
          }}
        >
          <PageTitleStyles>{t("enterEmailCode")}</PageTitleStyles>
          {email && (
            <>
              <PageDescriptionStyles
                style={{
                  fontSize: "14px",
                  color: "#595959",
                }}
              >
                {t("codeHasBeenSent")} {parseEmail(email)}
              </PageDescriptionStyles>
            </>
          )}

          <RICIBsWrapperStyles incorrect={incorrect}>
            <RICIBs
              inputProps={{
                style: {
                  color: "black",
                  border: "1px solid #CCCCCC",
                  height: "48px",
                  width: "36px",
                  fontSize: "20px",
                },
              }}
              amount={6}
              autoFocus
              password
              handleOutputString={handleString}
              inputRegExp={/^[a-zA-Z0-9]$/}
            />
          </RICIBsWrapperStyles>
          {incorrect && (
            <ErrorStyles style={{ textAlign: "center" }}>
              {t("invalidEmailCode")}
            </ErrorStyles>
          )}
          {prevConfirmed && (
            <>
              <ErrorStyles style={{ textAlign: "center", color: "black" }}>
                {t("emailHasBeenVerified")}
              </ErrorStyles>
              <BoxStyles mt={1}>
                <ButtonStyles onClick={() => navigate("/")}>
                  {t("login")}
                </ButtonStyles>
              </BoxStyles>
            </>
          )}
          {serverError && (
            <ErrorStyles style={{ textAlign: "center" }}>
              {t("serverError")}
            </ErrorStyles>
          )}
          {isLoading ? (
            <Circles color="#818181" height={50} width={50} />
          ) : (
            <>
              {!prevConfirmed && (
                <>
                  <ButtonStyles
                    style={{ marginTop: "10px" }}
                    onClick={handleClick}
                  >
                    {t("next")}
                  </ButtonStyles>
                </>
              )}
            </>
          )}
          {timeStatus > 0 ? (
            <>
              <BoxStyles
                mt={3}
                mb={3}
                height={40}
                width={40}
                style={{
                  border: "2px solid black",
                  textAlign: "center",
                  lineHeight: "40px",
                  borderRadius: "50%",
                }}
              >
                {timeStatus}
              </BoxStyles>
            </>
          ) : (
            <BoxStyles mt={3} mb={3} height={40}>
              Kod wygasł lub nie dotarł? &nbsp;
              <a
                style={{
                  color: "#CB004B",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  handleResend();
                  setTimeAlertVisible(false);
                }}
              >
                Wyślij Ponownie
              </a>
            </BoxStyles>
          )}
        </PageCenterWrapperStyles>
      </Layout>
    </>
  );
};

export default Authorization;
