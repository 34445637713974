import { NavLink as RouterNavLink, useMatch } from "react-router-dom";
import { StyledNavLinkStyles } from "./styles";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  const match = !!useMatch(to);

  return (
    <StyledNavLinkStyles match={match.toString()} navTo={to}>
      <RouterNavLink to={to}>{children}</RouterNavLink>
    </StyledNavLinkStyles>
  );
};

export default NavLink;
