import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import poland from '../assets/icons/poland.png';
import uk from '../assets/icons/united-kingdom.png';

const ButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateX(50%);
  margin-right: 10px;
`;

const LangButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  cursor: pointer;
  font-size: 1rem;
  margin: 0 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  return (
    <ButtonContainer>
      <LangButton onClick={() => i18n.changeLanguage('pl')}><img style={{width: '30px', height: '30px'}} src={poland} alt="My Image" /></LangButton>
      <LangButton onClick={() => i18n.changeLanguage('en')}><img style={{width: '30px', height: '30px'}} src={uk} alt="My Image" /></LangButton>
    </ButtonContainer>
  );
};

export default LanguageSwitch;
