import styled from "styled-components";

interface ImageStylesProps {
  url?: string;
}

export const RootStyles = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 132px;
  height: 132px;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
`;

export const ImageStyles = styled.div<ImageStylesProps>`
  ${({ url }) => `
    width: 132px;
    height: 132px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    ${url && `background-image: url('${url}');`}
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;
